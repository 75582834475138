import React, { useState } from 'react';
import { bubble as Slide, push as Menu } from 'react-burger-menu';
import "./Styles.css";

function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }


function MenuComponent({ children, isMobile }) {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenMobile, setIsOpenMobile] = useState(false);

    console.log("isOpenMobile");
    console.log(isOpenMobile);
    return (

    <>
     {isMobileDevice() ? (
          <Slide
          isOpen={false}
          disableCloseOnEsc
          onStateChange={(state) => setIsOpenMobile(state.isOpen)}
          pageWrapId={ "page-wrap" }
          outerContainerId={ "outer-container" }
      >
          {children}
      </Slide>
     ) : (
          <Menu
          isOpen={!isMobile || isOpen}
          noOverlay={!isMobile}
          disableCloseOnEsc
          // styles={menuStyles}
          onStateChange={(state) => setIsOpen(state.isOpen)}
          pageWrapId={ "page-wrap" }
          outerContainerId={ "outer-container" }
      >
          {children}
      </Menu>
     )} 
    </>
      
    );
}

export default MenuComponent;
