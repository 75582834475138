
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import { getAllDepositRequest } from '../../api/user';
import DepositRequestTable from '../../components/DepositRequestTable/DepositRequestTable';
import "./Styles.css"




const DepositPanelFundsHistory = () => { 


    const [depositRequestList, setDepositRequestList] = useState([]);
    const { t } = useTranslation();


    useEffect(() => {
        const email = localStorage.getItem("login");
        const token = localStorage.getItem("token");
       getAllDepositRequest( token, email).then(data => {
        setDepositRequestList(data.depositRequestArray)
       }).catch(e => {
        if(e.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("login");
            window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`);
          };
       })
    }, [])

    return (
        <div className="clientPanel">

        <div class="row page-titles">
                            <div class="flex-boxx">   
                               <h3 class="text-themecolor">{t("c70")}</h3>
                               
                                <div class="breadcrumb">
                                    <div class="breadcrumb-item">{t("c36")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">{t("c37")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">{t("c71")}</div>
                                </div>
                            </div>          
        </div>

        <div className='deposit_bottom_panel'>
    <h2>{t("c70")}</h2>
    <div className='deposit_bottom_panel_table'>
        {depositRequestList.length === 0 ? 
        <span>{t("c72")}</span> :
        <DepositRequestTable data={depositRequestList}/>
    }
        
    </div>

</div>


        </div>
    )
}


    export default DepositPanelFundsHistory;