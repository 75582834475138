
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";


// import { referalInfo } from '../../api/user';

import { fetchInfo, changePassword } from '../../api/user'

import "./Styles.css"




const Settings = () => { 

    const [data, setData] = useState({});
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatNewPassword, setRepeatNewPassword] = useState("");


    const { t } = useTranslation();

    useEffect(() => {
        const login = localStorage.getItem("login");
        const token = localStorage.getItem("token");

       fetchInfo(login, token).then(data => {
           console.log(data);
            setData(data)
      
        }).catch(e => {
            if(e.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("login");
                window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`);
              };
        })
    }, [])


    const onClick = () => {
        if(newPassword !== repeatNewPassword) {
            alert("Новые пароли не совпадают.")
        } else {

            const token = localStorage.getItem("token");
            changePassword(data.email, oldPassword, newPassword, token ).then(data => {
                alert("Пароль успешно обновлен!");
            }).catch(e => {
                alert("Вы ввели неверный пароль.");
            })
        }
    }

    return (
        <div className="clientPanel">

        <div class="row page-titles">
                            <div class="flex-boxx">   
                               <h3 class="text-themecolor">{t("c98")}</h3>
                               
                                <div class="breadcrumb">
                                    <div class="breadcrumb-item">{t("c36")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">{t("c37")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">Настройки</div>
                                </div>
                            </div>          
        </div>



<div className='deposit-background'>



<div className="deposit_panel_main">


<div className='settingBox'>
    <div className='settingBox_inner text-themecolor'>
        <div>Почта</div>
        <div className='padding_top'>{data.email}</div>
    </div>
    <div className='settingBox_inner text-themecolor'>
        <div>Имя</div>
        <div className='padding_top'>{data.name}</div>
    </div>
    <div className='settingBox_inner text-themecolor'>
        <div>Фамилия</div>
        <div className='padding_top'>{data.surname}</div>
    </div>
    <div className='settingBox_inner text-themecolor'>
        <div>Тел.</div>
        <div className='padding_top'>{data.phone}</div>
    </div>
    <div className='settingBox_inner text-themecolor'>
        <div>Регион</div>
        <div className='padding_top'>{data.region}</div>
    </div>
    <div className='settingBox_inner text-themecolor'>
        <div>Логин ТГ</div>
        <div className='padding_top'>{data.telegram}</div>
    </div>

{/* 
    <div className='settingBox_inner text-themecolor'>
        <div className='settings_big_text'>Смена пароля</div>

        <div className='margin_top'>
            <span>Старый пароль:</span>
            <input  className='margin_top_input' type="text" placeholer="Введите старый пароль" onChange={e => setOldPassword(e.target.value)}/>
        </div>

        
        <div className='margin_top'>
            <span>Новый пароль:</span>
            <input  className='margin_top_input' type="text" placeholer="Введите новый пароль" onChange={e => setNewPassword(e.target.value)}/>
        </div>

            
        <div className='margin_top'>
            <span>Повторите новый пароль:</span>
            <input  className='margin_top_input' type="text" placeholer="Повторите новый пароль" onChange={e => setRepeatNewPassword(e.target.value)}/>
        </div>
        
        <button type="submit"  onClick={onClick} className='btn-sok margin_top'>Сменить пароль</button>

    </div> */}
   </div>

</div>




</div>
{/* <div className='deposit_bottom_panel'>
    <h2>Список открытых и закрытых депозитов</h2>
    <div>
        <span className='dataTime'>Дата и Время: 20 Ноября 03:29</span>
    </div>
</div> */}


        </div>
    )
}


    export default Settings;