import React, { useEffect, useState, useRef } from 'react';
import   ReactTable  from "react-table";
import { TextField, Button } from "@material-ui/core";

import {  getWithdrawsByAdmin, fillWithdrawRequest } from '../../api/user';
// import "./Styles.css"


import "react-table/react-table.css";





 const AdminPanelWithdraw = () => {


const [isOpenModal, setIsOpenModal] = useState(false); 
const [msgFromHood, setMsgFromHood] = useState(""); 


const [data, setData] = useState([]);
const [currentPage, setCurrentPage] = useState(1); 


useEffect(() => {
  console.log(currentPage);
     const token = localStorage.getItem("token");
     getWithdrawsByAdmin(token, currentPage).then(data => {
      setData(data);
     })
}, [currentPage])


// const toggleContact = () => setIsOpenModal(!isOpenModal); 
// const modalRef = useRef(null);



const closeDepositHandle = (email, sum, status, id) => {
  if(status === "Успешно") {
    alert("Запрос не актуален.")
    return;
  } else {
  const token = localStorage.getItem('token');
  fillWithdrawRequest(token, {email, sum, id}).then(data => {
    console.log(data);
    setMsgFromHood(data.message);
    setIsOpenModal(true);
  })

  }
}


  return (
    <>
     <div>
      <ReactTable
        data={data}
        columns={[
          {
            Header: "Email",
            accessor: "withdrawOwnerEmail",

          },
          {
            Header: "Способ оплаты",
            accessor: "withdrawMethod",

          },
          {
            Header: "Дата запроса",
            accessor: "withdrawDate",

          },
          {
            Header: "Сумма",
            accessor: "withdrawSum",
          },
          {
            Header: "Статус",
            accessor: "status",
          },
          {
            Header: "Реквизиты для вывода",
            accessor: "addressWithdraw",
          },
          {
            Header: "Подтвердить вывод средств",
            accessor: "id",
            Cell: props => (
              <Button onClick={() => closeDepositHandle(props.original.withdrawOwnerEmail, props.original.withdrawSum, props.original.status, props.original._id
                )}>Подтвердить вывод средств</Button>
            )
          }
        ]}

        showPagination={false}
        showPaginationBottom={true}
        className="-striped -highlight"
      >
        {/* {(state, makeTable, instance) => {
          console.log("UPDATE");
          return makeTable();
        }} */}
      </ReactTable>
      <br />
      <div className='pagination-panel'>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev - 1)}>{`< НАЗАД`}</div>
        <div className='pagination-panel_page'>{currentPage}</div>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev + 1)}>{`ВПЕРЕД >`}</div>
      </div>

      <div className='pagination-panel'>
     
      </div>

      {  isOpenModal ? 
  <div className='adminModal'>
    <div>
    {msgFromHood}
    <button type="submit" onClick={() => window.location.reload() } className='btn-dep'>ОК </button>
    </div>
   </div> 
   : null   }

   
    </div>

   
   
    </>
  );
};

export default AdminPanelWithdraw;
