import React from 'react';
import { useTranslation } from "react-i18next";
import   ReactTable  from "react-table";

import "./Styles.css"
import "react-table/react-table.css";



 const DepositRequestTable = ({data}) => {

  const { t } = useTranslation();
 console.log(data);
  return (
    <>
    <div>
      <ReactTable
        data={data}
        columns={[
          {
            Header: t("r52"),
            accessor: "depositSum",
          },
          {
            Header: t("r53"),
            accessor: "depositDate",
          },
          {
            Header: t("r54"),
            accessor: "depositMethod",
          },
          {
            Header: t("r51"),
            accessor: "status",
          },
        ]}

        showPagination={false}
        showPaginationBottom={true}
        defaultPageSize={data.length}
        className="-striped -highlight"
      >
        {/* {(state, makeTable, instance) => {
          console.log("UPDATE");
          return makeTable();
        }} */}
      </ReactTable>
      <br />
      {/* <div className='pagination-panel'>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev - 1)}>{`< НАЗАД`}</div>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev + 1)}>{`ВПЕРЕД >`}</div>
      </div> */}
    </div>
    </>
  );
};

export default DepositRequestTable;
