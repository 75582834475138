
export const formValues = [
    {
        id: '1',
        name: 'login',
        label: 'fl',
        type: 'text',
        placeholder: 'plLogin',
        d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
        cx: "12",
        cy: "7",
        r: "4"
    },
    {
        id: '2',
        name: 'email',
        label: 'E-MAIL',
        type: 'text',
        placeholder: 'plEmail',
        d: "M 16 8 v 5 a 3 3 0 0 0 6 0 v -1 a 10 10 0 1 0 -3.92 7.94",
        cx: "12",
        cy: "12",
        r: "4"
    },
    {
        id: '3',
        name: 'password',
        label: 'fp',
        type: 'text',
        placeholder: '*****',
        d: "M 7 11 V 7 a 5 5 0 0 1 10 0 v 4",
        cx: "3",
        cy: "11",
        r: "4"
    },
    {
        id: '3',
        name: 'confirmpassword',
        label: 'fcp',
        type: 'text',
        placeholder: '*****',
        d: "M 7 11 V 7 a 5 5 0 0 1 10 0 v 4",
        cx: "3",
        cy: "11",
        r: "4"
    },

    { id: '4', 
    name: 'name', 
    label: 'fn', 
    placeholder: 'plName',     
    d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
    cx: "12",
    cy: "7",
    r: "4" },


    {
        id: '5',
        name: 'surname',
        label: 'fs',
        type: 'text',
        placeholder: 'plSur',
        d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
        cx: "12",
        cy: "7",
        r: "4"
    },
    {
        id: '6',
        name: 'region',
        label: 'Ваш регион',
        type: 'text',
        placeholder: 'Ваш регион',
        d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
        cx: "12",
        cy: "7",
        r: "4"
    },
    {
        id: '7',
        name: 'telegram',
        label: 'TELEGRAM',
        type: 'text',
        placeholder: 'Ваш логин Telegram',
        d: "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
        cx: "12",
        cy: "7",
        r: "4"
    },
    
];



export const formLoginValues = [

    {
        id: '1',
        name: 'email',
        label: 'E-MAIL',
        type: 'text',
        placeholder: 'Ваша почта',
        d: "M 16 8 v 5 a 3 3 0 0 0 6 0 v -1 a 10 10 0 1 0 -3.92 7.94",
        cx: "12",
        cy: "12",
        r: "4"
    },
    {
        id: '2',
        name: 'password',
        label: 'ПАРОЛЬ',
        type: 'text',
        placeholder: '*****',
        d: "M 7 11 V 7 a 5 5 0 0 1 10 0 v 4",
        cx: "3",
        cy: "11",
        r: "4"
    },

    
];