import React, { useEffect, useState, useRef } from 'react';
import   ReactTable  from "react-table";
import { TextField, Button } from "@material-ui/core";

import { addAmountToDeposit, subDepositToEmail, getRequestsByAdmin, fillDepositRequest } from '../../api/user';
import "./Styles.css"


import "react-table/react-table.css";





 const AdminPanelAccount = () => {
const [currentEmailToDeposit, setCurrentEmailToDeposit] = useState("");
const [currentSumToDeposit, setCurrentSumToDeposit] = useState("");

const [currentEmailToSubDeposit, setCurrentEmailToSubDeposit] = useState("");
const [currentSumToSubDeposit, setCurrentSumToSubDeposit] = useState("");

const [isOpenModal, setIsOpenModal] = useState(false); 
const [msgFromHood, setMsgFromHood] = useState(""); 


const [data, setData] = useState([]);
const [currentPage, setCurrentPage] = useState(1); 


useEffect(() => {
  console.log("currentPage:");
  console.log(currentPage);
     const token = localStorage.getItem("token");
     getRequestsByAdmin(token, currentPage).then(data => {
      setData(data);
     })
}, [currentPage])


// const toggleContact = () => setIsOpenModal(!isOpenModal); 
// const modalRef = useRef(null);

const fillDepositToEmail = () => {
  console.log(currentEmailToDeposit);
  console.log(currentSumToDeposit)
  const token = localStorage.getItem('token');
  debugger;
  addAmountToDeposit(token, { currentEmailToDeposit, currentSumToDeposit}).then(data => {
    console.log(data);
    setMsgFromHood(data.message);
    setIsOpenModal(true);
  })
}

const subDepositToEmailHandler = () => {
  const token = localStorage.getItem('token');
  console.log(currentSumToSubDeposit);
  subDepositToEmail(token, { currentEmailToSubDeposit, currentSumToSubDeposit}).then(data => {
    if(data.message) {
      setMsgFromHood(data.message);
      setIsOpenModal(true);
    }
 
  })
}


const closeDepositHandle = (email, sum, status, id) => {
  console.log(status);
  if(status === "Успешно") {
    alert("Запрос не актуален.")
    return;
  } else {

        // console.log(email);
  const token = localStorage.getItem('token');
  fillDepositRequest(token, {email, sum, id}).then(data => {
    console.log(data);
    setMsgFromHood(data.message);
    setIsOpenModal(true);
  })

  }
}

//   const [data, setData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1); 
 

//   useEffect(() => {
//     console.log("currentPage:");
//     console.log(currentPage);
//        const token = localStorage.getItem("token");
//        getDepositsByAdmin(token, currentPage).then(data => {
//         setData(data);
//        })
//   }, [currentPage])


  // const updateCellData = (idx, id, value) => {
  //   console.log(id);


  //   if(id === "depositOwner.deposit") {
  //     const token = localStorage.getItem('token');
  //     const emailToUpdate = data[idx].depositOwner.email;
  //     console.log(emailToUpdate);
  //     changeDepositBalanceByAdmin(token, emailToUpdate, value).then(data => {
  //       console.log(data);
  //     })
  //   }

  //   if(id === "isAvailable") {
  //     const token = localStorage.getItem('token');
  //     const idToUpdate = data[idx]._id;
  //     console.log(idToUpdate);
  //     changeStatusByAdmin(token, idToUpdate, value).then(data => {
  //       console.log(data);
  //     })
  //   }

 

    // console.log(data[idx].ids);
    // const newData = [...data];
    // newData[idx][id] = value;
    // console.log(`prev: ${data[idx][id]}, new: ${newData[idx][id]}`);
    // setData(newData);
  // };


//   const closeDepositHandle = (email, totalDepositSum, profitSum, idDeposit, status) => {
//     if(status === "Закрыт") {
//       alert("Депозит уже закрыт")
//       return;
//     } else {

//           // console.log(email);
//     const token = localStorage.getItem('token');
//     closeDeposit(token, {email, totalDepositSum, profitSum, idDeposit}).then(data => {
//       console.log(data);
//     })

//     }



//   }



//  console.log(data);
  return (
    <>
     <div>
      <ReactTable
        data={data}
        columns={[
          {
            Header: "Email",
            accessor: "depositOwnerEmail",

          },
          {
            Header: "Способ оплаты",
            accessor: "depositMethod",

          },
          {
            Header: "Дата запроса",
            accessor: "depositDate",

          },
          {
            Header: "Сумма",
            accessor: "depositSum",
          },
          {
            Header: "Статус",
            accessor: "status",
          },
          {
            Header: "Пополнить баланс клиента",
            accessor: "id",
            Cell: props => (
              <Button onClick={() => closeDepositHandle(props.original.depositOwnerEmail, props.original.depositSum, props.original.status, props.original._id
                )}>Подтвердить пополнение баланса</Button>
            )
          }
        ]}

        showPagination={false}
        showPaginationBottom={true}
        className="-striped -highlight"
      >
        {/* {(state, makeTable, instance) => {
          console.log("UPDATE");
          return makeTable();
        }} */}
      </ReactTable>
      <br />
      <div className='pagination-panel'>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev - 1)}>{`< НАЗАД`}</div>
        <div className='pagination-panel_page'>{currentPage}</div>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev + 1)}>{`ВПЕРЕД >`}</div>
      </div>

      <div className='pagination-panel'>
     
      </div>
    </div>

    <div className='bottomAdminPanel'>
      <h3>Дополнительный функционал, использовать только при крайней необходимости. Все управления депозитами клиентов осуществляется через таблицы. Данный функционал реализован для дополнительных функций (например - праздничное начисление средств участникам проекта)</h3>
      <div className='adminPanel2'>


<div className='handleDeposit'>
  <span>Введите email пользователя которому поплняем депозит:</span>
<input  type="text" placeholer="Введите email"  onChange={e => setCurrentEmailToDeposit(e.target.value)}/>
<span>Введите сумму депозита:</span>
<input  type="text" placeholer="Введите сумму"  onChange={e => setCurrentSumToDeposit(e.target.value)}/>
<button type="submit" onClick={fillDepositToEmail} className='btn-dep'>Пополнить </button>
</div>

<div className='handleDeposit'>
<span>Введите email пользователя у которого хотите вычесть средства с Баланса:</span>
<input  type="text" placeholer="Введите email"  onChange={e => setCurrentEmailToSubDeposit(e.target.value)}/>
<span>Введите сумму:</span>
<input  type="text" placeholer="Введите сумму"  onChange={e => setCurrentSumToSubDeposit(e.target.value)}/>
<button type="submit" onClick={subDepositToEmailHandler} className='btn-dep'>Вычесть </button>
</div>

  {  isOpenModal ? 
  <div className='adminModal'>
    <div>
    {msgFromHood}
    <button type="submit" onClick={() => window.location.reload() } className='btn-dep'>ОК </button>
    </div>
   </div> 
   : null   }
</div>
    </div>
 
   
    </>
  );
};

export default AdminPanelAccount;
