import React from 'react';

import './Styles.css';

const AdminHeader = ({props}) => {

  console.log(process.env);
  console.log(process.env.PUBLIC_URL);

  

  return (
    <div className='admin-header'>
    <div className='admin-header-inner'>
    <div onClick={() => window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/AdminPanelCustomer`)}>Список клиентов</div>
        <div onClick={() => window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/adminPanel`)}>Список депозитов</div>
        <div onClick={() => window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/adminPanelAccount`)}>Заявки на пополнение баланса</div>
        <div onClick={() => window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/adminPanelWithdraw`)}>Заявки на вывод средств</div>
        <div onClick={() => window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/adminPanelAddress`)}>Реквизиты</div>
    </div>
  </div>
  )

};



export default AdminHeader;
