import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      'EN': {
        translations: {
          "To get started, edit <1>src/App.js</1> and save to reload.":
            "To get started, edit <1>src/App.js</1> and save to reload.",
          "Reg": "Registration",
          reg: "Registration",
          alr: "Already have an account?",
          plLogin: "Your login",
          plEmail: "Your email",
          plName: 'Your name',
          plSur: "Your phone",
          auth: "Login",
          fl: "LOGIN",
          fp: "PASSWORD",
          fcp: "CONFIRM PASSWORD",
          fn: "NAME",
          fs: "PHONE",
          f1: "I agree with",
          f2: "conditions",
          f3: "using the Renaissance-Invest service, as well as receiving email and sms mailings",
          rg: 'SIGN UP',
          rgc: "Registration completed!",
          rgc2: "Go to personal account",
          c1: "Choose a plan",
          c2: "Available funds",
          c3: "Enter the deposit amount:",
          c4: "You can invest from 100 $ to 30,000,000 $",
          c5: 'Deposit personal information',
          c6:  "Accrued profit",
          c7: "Output amount",
          c8: "Deposit end date",
          c9: "Bid:",
          c10: "Bonus:",
          c11: "Interest capitalization:",
          c12: "Limitation on the number of active investments:",
          c13: "For 24 hours",
          c14: "For 7 days",
          c15: "For 14 days",
          c16: "For 31 days",
          c17: "Rate",
          c18: "Yield",
          c19: "per day",
          c20: "at the end of the deposit period.",
          c21: "The body of the investment is returned after the end of the investment period. The first accrual for tariff plans 'for 7 days', 'for 14 days', 'for 30 days' occurs 24 hours after the creation of the deposit, for the tariff plan 'for 24 hours' accrual occurs instantly.",
          c22: "There are no restrictions.",
          c23: "Deposit for 24 hours",
          c24: "2.3% rate per day",
          c25: "Deposit refund after 24 hours",
          c26: "Deposit for 7 days",
          c27: "2.3% rate per day",
          c29: "Deposit for 14 days",
          c30: "3.3% rate per day",
          c31: "Deposit for 31 days",
          c32: "4.3% rate per day",
          c33: "CREATE DEPOSIT",
          c34: "Yes",
          c35: "Choice of tariff plans",
          c36: "Home",
          c37: "Personal account",
          c38: "Create deposit",
          c39: "List of deposits and financial account statistics",
          c40: "List of deposits and account statistics",
          c41: "Available funds - this is the amount on your personal balance, which is available for both withdrawal and deposit",
          c42: "Your options:",
          c43: "Top up your balance",
          c44: "Create a new deposit",
          c45: "Withdraw funds",
          c46: "Your financial statistics",
          c47: "Total topped up:",
          c48: "The total amount you contributed to Renaissance-Invest",
          c49: "Total output:",
          c50: "The total amount of funds withdrawn by you",
          c51: "Active deposits:",
          c52: "The total amount of all active deposits",
          c53: "Refferal program:",
          c54: "The total amount of funds earned through the referral program",
          c55: "Balance replenishment",
          c56: "Replenishment method",
          c57: "Choose a payment system",


          c58: "Qiwi Wallet",
          c59: "Commission 0%",
          c60: "Bank card",
          c61: "Commission 5% + 1%",
          c62: "Gas fee",
          c63: "Gas fee",
          c64: "Commission 8% + 2%",
          c65: "Commission 2%",
          c66: "Commission 0.03 Doge",
          c67: "SberBank",
          c68: "Commission 3% + 1%",
          c69: "Top up",


          c70: "History of replenishment",
          c71: "Top up history",
          c72: "You do not have a balance replenishment history.",
          c73: "Create a withdrawal request",
          c74: "Withdrawals",
          c75: "Choose a payment system for withdrawing funds",


          c76: "Withdrawal instructions",
          c77: "Choose a payment system",
          c78: "Enter the amount (the minimum amount for withdrawal is 100 ₽)",
          c79: "Attention!",
          
          c80: "Make sure your payment details are correct",
          c81: "Within 24 hours you will receive a payment to your chosen e-wallet.",
          c82: "Limits:",
          c83: "During the working day, an application for withdrawal of funds can be created once. The interval between the creation of applications must be at least 24 hours.",
          c84: "Enter the amount for your chosen method:",
          c85: "Enter details:",
          c86: "Send",
          c87: "Close",
          c88: "Choose a payment system for withdrawing funds",
          c89: "Create an application for ",
          c90: "card",


          c91: "Instructions for replenishing funds",
          c92: "Top up balance via:",
          c93: "Enter the amount (The maximum amount of one transfer is 100,000 rubles)",
          c94: "Number of transfers: unlimited",
          c95: "Make payment to the following details:",
          c96: "Enter the deposit amount:",
          c97: "Paid",

          c98:"Affiliate program",


          r10: "Arbitrate or own a thematic traffic source?",
          r111: "We are ready to offer individual conditions",
          r12: "Contact Center",
          r13: "Each registered member of CrossChain Bridge can use his referral link to invite partners and receive rewards from your referral's initial deposit.",
          r14: "Partner reward:",
          r15: "5% of the deposit amount",
          r16: "Example:",
          r17: "The person you invited has invested 20,000 rubles, you will automatically receive a partnership reward of 1,000 rubles on the main balance.",
          r18: "Follow the rules of the affiliate program:",
          r19: "It is forbidden to register your accounts under your referral system.",
          r20: "Your financial statistics",
          r21: "Your referral link:",
          r22: "Copy link",
          r23: "Referral income:",
          r24: "The total amount of your income from attracted partners",
          r25: "Total unique clicks:",
          r26: "The total number of unique clicks from",
          r27: "Total referrals:",
          r28: "The total number of partners attracted by you",
          r29: "Active deposits of referrals:",
        r30: "The total number of active deposits of referrals",
        r31: "It is forbidden to spam your referral link in various groups and in comments on the social network VKontakte.",
          r32: "Referral history",
          r333: "You don't have referrals.",
        r34: "List of open and closed deposits",
        r35: "You have no deposits.",
          r36: "Your deposits",
        r37: "Statistics",
        r38: "Top up balance",
        r39: "Choose a payment method",
          r40: "Withdrawal history",
          r41: "Affiliate links",
        r42: "Partner statistics",
        r43: "Logout",
          r44: "Hello",
          r45: "Account statistics and list of deposits",
          
          
          r47: "Deposit amount",
          r48: "Days of deposit",
          r49: "Amount to withdraw",
          r50: "Expiration date",
          r51: "Status",
          r52: "Sum",
          r53: "Date",
          r54: "Payment service",
          r55: "List of withdrawals",
          r56: "You have no withdrawal history.",
          r57: "Withdrawal details",
          r58: "History of withdrawal of funds",
          r59: "Withdrawal history",
          r60: "",
          r61: "",
          r62: "",
        }
      },
      'ru-RU': {
        translations: {
          "To get started, edit <1>src/App.js</1> and save to reload.":
            "Starte in dem du, <1>src/App.js</1> editierst und speicherst.",
          "Reg": "Регистрация",
          reg: "Регистрация",
          alr: "Уже есть аккаунт?",
          plLogin: "Ваш логин",
          plEmail: 'Ваша почта',
          plName: "Ваше имя и фамилия",
          plSur: "Ваш номер",
          auth: 'Авторизироваться',
          fl: "ЛОГИН",
          fp: "ПАРОЛЬ",
          fcp: "ПОДТВЕРДИТЕ ПАРОЛЬ",
          fn: "ИМЯ И ФАМИЛИЯ",
          fs: "НОМЕР ТЕЛЕФОНА",
          f1: "Я согласен с",
          f2: "условиями",
          f3: "использования сервиса Renaissance-Invest, а также на получения email и sms рассылок",
          rg: "ЗАРЕГИСТРИРОВАТЬСЯ",
          rgc: "Регистрация завершена!",
          rgc2: "Перейти в личный кабинет",
          c1: "Выберите тарифный план",
          c2: "Доступные средства",
          c3: "Введите сумму депозита:",
          c4: "Вы можете вложить от 100 $",
          c5: "Персональная информация по депозиту",
          c6:  "Начисленная прибыль",
          c7: "Сумма на выходе",
          c8: "Дата окончания депозита",
          c9: "Ставка:",
          c10: "Бонус:",
          c11: 'Капитализация процентов:',
          c12: 'Ограничение по количеству активных инвестиций:',
          c13: "На 24 часа",
          c14: "На 7 дней",
          c15: "На 14 дней",
          c16: "На 31 день",
          c17: "Тариф",
          c18: "Доходность",
          c19: "в день",
          c20: "в конце депозитного периода.",
          c21: "Тело инвестиции возвращается после завершения инвестиционного периода. Начисление происходит моментально.",
          c22: "Ограничения отсутствуют.",
          c23: "Вклад на 24 часа",
          c24: "2.3% ставка в день",
          c25: "Возрат депозита через 24 часа",
          c26: "Вклад на 7 дней",
          c27: "2.3% ставка в день",
          c29: "Вклад на 14 дней",
          c30: "3.3% ставка в день",
          c31: "Вклад на 31 день",
          c32: "4.3% ставка в день",
          c33: "СОЗДАТЬ ДЕПОЗИТ",
          c34: "Есть.",
          c35: "Выбор тарифных планов",
          c36: "Главная",
          c37: "Личный кабинет",
          c38: "Создать депозит",
          c39: "Список депозитов и финансовая статистика аккаунта",
          c40: "Список депозитов и статистика аккаунта",
          c41: "Доступные средства - это сумма на вашем персональном балансе, которая доступна как для вывода так и для депозитирования",
          c42: "Ваши возможности:",
          c43: "Пополнить свой баланс",
          c44: "Создать новый депозит",
          c45: "Вывести средства",
          c46: "Ваша финансовая статистика",
          c47: "Всего пополнено:",
          c48: "Общая сумма внесенных Вами средств в компанию Renaissance-Invest",
          c49: "Всего выведено:",
          c50: "Общая сумма выведенных Вами средств",
          c51: "Активные депозиты:",
          c52: "Общая сумма всех активных депозитов",
          c53: "Партнерская программа:",
          c54: "Общая сумма средств, заработанных по реферальной программе",
          c55: "Пополнение баланса",
          c56: "Способ пополнения",
          c57: "Выберите платежную систему",

          c58: "Киви Кошелек",
          c59: "Комиссия 0%",
          c60: "Банковская карта",
          c61: "Комиссия 5% + 1%",
          c62: "Комиссия Gas fee",
          c63: "Комиссия BEP20 gas fee",
          c64: "Комиссия 8% + 2%",
          c65: "Комиссия 2%",
          c66: "Комиссия 0.03 Doge",
          c67: "СберБанк",
          c68: "Комиссия 3% + 1% НДС",
          c69: "Пополнить",
          c70: "История пополнения лицевого баланса",
          c71: "История пополнения баланса",
          c72: "У вас нет истории пополнения баланса.",
          c73: "Создать заявку на вывод средств",
          c74: "Вывод средств",
          c75: "Выберите платежную систему для вывода денежных средств",
          
          c76: "Инструкция по выводу средств",
          c77: "Выберите платежную систему",
          c78: "Введите сумму в долларах (минимальная сумма для вывода - 10$ для электронных кошельков и Swift и 1$ в эквиваленте для криптовалюты)",
          c79: "Внимание!",

          c80: " Убедитесь в правильности Ваших платежных реквизитов",
          c81: "В течение 48-и часов вы получите выплату на выбранный Вами электронный кошелёк.",
          c82: "Лимиты:",
          c83: " В течении суток заявка на вывод средств может быть создана один раз. Интервал между созданием заявок должен составлять не менее 24-х часов.",
          c84: "Введите сумму для выбранного вами способа:",
          c85: "Введите реквизиты:",
          c86: "Отправить",
          c87: "Закрыть",
          c88: "Выберите платежную систему для вывода денежных средств",
          c89: "Создать заявку на ",
          c90: "карту",
          c91: "Инструкция по пополнению средств",
          c92: "Пополнить баланс через:",
          c93: "Укажите сумму ввода в долларах (Максимальная сумма одного перевода — 50 000 $)",
          c94: "Количество переводов: неограниченно",
          c95: "Сделайте оплату на следующие реквизиты в валютном эквиваленте учитывая комиссии выбраной платежной системы:",
          c96: "Введите сумму попонения:",
          c97: "Оплачено",
          c98: "Партнерская программа",
          c99: "Партнерские ссылки",
          c100: "",
          c101: "",
          


          r10: "Занимаетесь рекламой, владеете тематическим источником трафика, или активный лидер в сетевом бизнесе?",
          r111: "Мы готовы предложить индивидуальные условия",
          r12: "Контактный центр",
          r13: "Каждый инвестор компании Renaissance-Invest может воспользоваться своей реферальной ссылкой для приглашения партнеров и получать вознаграждения от первоначального депозита Вашего реферала.",
          r14: "Партнерское вознаграждение:",
          r15: "8% от суммы депозита",
          r16: "Пример:",
          r17: "Приглашенный Вами человек инвестировал $10 000, Вы автоматически получаете на основной баланс партнерское вознаграждение $800. Далее по ставке.",
          r18: "Соблюдайте правила партнерской программы:",
          r19: "Запрещается регистрировать свои аккаунты под свою реферальную систему.",
          r20: "Ваша финансовая статистика",
          r21: "Ваша ссылка для привлечения рефералов:",
          r22: "Скопировать ссылку",
          r23: "Доход с рефералов:",
          r24: "Общая сумма Ваших доходов с привлеченных партнеров",
          r25: "Всего уникальных переходов:",
          r26: "Общее количество уникальных переходов",
          r27: "Всего рефералов:",
          r28: "Общее количество привлеченных Вами партнеров",
          r29: "Активных депозитов рефералов:",
        r30: "Общее количество активных депозитов рефералов",
        r31: "Запрещается спамить свою реферальную ссылку в различных группах и в комментариях в соц.сетях.",
          r32: "История рефералов ",
          r333: "У вас нет рефералов.",
        r34: "Список открытых и закрытых депозитов",
        r35: "У вас нет депозитов.",
          r36: "Ваши депозиты",
        r37: "Статистика",
        r38: "Пополнить баланс",
        r39: "Выбрать способ пополнения",
          r40: "История вывода средств",
          r41: "Партнерские ссылки",
        r42: "Статистика партнеров",
        r43: "Выход",
          r44: "Здравствуйте",
          r45: "Статистика аккаунта и список депозитов",
        
        
          r47: "Сумма депозита",
          r48: "Дней депозита",
          r49: "Сумма к выводу",
          r50: "Дата окончания",
          r51: "Статус",
          r52: "Сумма",
          r53: "Дата",
          r54: "Платежная система",
          r55: "Список выводов средств",
          r56: "У вас нет истории вывода средств.",
          r57: "Реквизиты для вывода",
          r58: "История вывода финансовых средств",
          r59: "История вывода средств",
          r60: "",
          r61: "",
          r62: "",

        }
      }
    },
    fallbackLng: "ru-RU",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
