import React from 'react';

import { useTranslation } from "react-i18next";
// import { useHistory } from 'react-router-dom';
// import SLUGS from 'resources/slugs';

// import { convertSlugToUrl } from 'resources/utilities';
// import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';


import Img1 from '../../image/money.png';
import Img2 from '../../image/team.png';
import Img3 from '../../image/budget.png';
import Img4 from '../../image/up.png';
import Icon from '../../image/updLogo.png';




import "./Styles.css"



function SidebarComponent() {
    // const { push } = useHistory();
    const isMobile = window.innerWidth <= 1080;

    const { t } = useTranslation();

    async function logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("login");
        window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`);
    }

    function onClick(slug, parameters = {}) {
        // push(convertSlugToUrl(slug, parameters));
    }

    return (
        <Menu  isOpen={isMobile}
        >
            <div  className="logo-wrapper" >
                <div className='first'>  
                <img src={Icon}/>
                </div>
         
                 {/* <div className='text-wrapper'>
                    <div>Multichain</div>
                    <div>Bridge</div>
                    </div> */}
            </div>


              <MenuItem
                id={"/link"}
                items={["/link", "/link"]}
                title={t("r36")}
                icon={Img1}
            >
                <MenuItem
                    id={"/link"}
                    title={t("c38")}
                    level={2}
                
                    onClick={() => onClick(window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client`))}
                />
                <MenuItem
                    id={"/link"}
                    title={t("r37")}
                    level={2}
       
                    onClick={() => onClick(window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/deposit`))}
                />
          
            </MenuItem>

            <MenuItem
                id={"/link"}
                items={["/link", "/link"]}
                title={t("r38")}
                icon={Img2}
            >
                <MenuItem
                    id={"/link"}
                    title={t("r39")}
                    level={2}
     
                    onClick={() => onClick(window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/deposit-funds`))}
                />
                <MenuItem
                    id={"/link"}
                    title={t("c71")}
                    level={2}
       
                    onClick={() => onClick(window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/deposit-funds-history`))}
                />
          
            </MenuItem>

            <MenuItem
                id={"/link"}
                items={["/link", "/link"]}
                title={t("c74")}
                icon={Img3}
            >
                <MenuItem
                    id={"/link"}
                    title={t("c73")}
                    level={2}
       
                    onClick={() => onClick(window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/withdraw`))}
                />
                <MenuItem
                    id={"/link"}
                    title={t("r40")}
                    level={2}

                    onClick={() => onClick(window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/withdraw-history`))}
                />
          
            </MenuItem>

            <MenuItem
                id={"/link"}
                items={["/link", "/link"]}
                title={t("c98")}
                icon={Img4}
            >
                <MenuItem
                    id={"/link"}
                    title={t("r41")}
                    level={2}
        
                    onClick={() => onClick(window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/partner`))}
                />
                <MenuItem
                    id={"/link"}
                    title={t("r42")}
                    level={2}

                    onClick={() => onClick(window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/partner-all`))}
                />
          
            </MenuItem>


           

            <div className="separator"/>

            <MenuItem id='logout' title={t("r43")} icon={Img2} onClick={logout} />

        </Menu>
    );
}

export default SidebarComponent;
