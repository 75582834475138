import React, { useEffect, useState } from 'react';

import { getAllDeposits, getDepositSum } from '../../api/user';
import DepositTable from '../../components/DepositTable/DepositTable';

import icon7 from '../../image/icon7.png';

import icon3 from '../../image/icon3.png';
import icon4 from '../../image/icon4.png';
import icon5 from '../../image/icon5.png';
import icon6 from '../../image/icon6.png';

import "./Styles.css"
import { t } from 'i18next';


const DepositPanel = () => {

    const [deposit, setDeposit] = useState(0);
    const [depositArray, setDepositArray] = useState([]);
    const [activeDepositSum, setActiveDepositSum] = useState(0);
    const [allTimeCost, setAllTimeCost] = useState(0);
    const [allTimeCostWithdraw, setAallTimeCostWithdraw] = useState(0);
    const [currentYieldFromReferal, setYieldFromReferal] = useState(0);

    useEffect(() => {
        const email = localStorage.getItem("login");
        const token = localStorage.getItem("token");
       getAllDeposits(email, token).then(data => {
        setDeposit(data.deposit.toFixed(2));
        setDepositArray(data.depositArray);
        console.log(data);


        
       }).catch(e => {
        console.log(e)
        if(e.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("login");
            window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`);
          };
       })

       getDepositSum(email, token).then(data => {
        console.log(data);
        setAllTimeCost(data.allTimeDeposit);
        setAallTimeCostWithdraw(data.allTimeWithdraw);
        setYieldFromReferal(data.yieldFromReferal);
        
       })
    }, [])

    useEffect(() => {
        if(depositArray.length !== 0) {
               let sum = 0;
               for(let i=0; i<depositArray.length; i++) {
                   if(depositArray[i].isAvailable === "Открыт") {
                    console.log(depositArray[i].depositSum);
                    sum += depositArray[i].depositSum;
                   }
               }
               setActiveDepositSum(sum);
        }
    }, [depositArray])

    console.log("activeDepositSum");
 console.log(activeDepositSum);
    return (

        <div className="clientPanel">

<div class="row page-titles">
                    <div class="flex-boxx">   
					   <h3 class="text-themecolor">{t("c39")}</h3>
					   
                        <div class="breadcrumb">
                            <div class="breadcrumb-item">{t("c36")}</div>
                            <div className='next'>&gt;</div>
                            <div class="breadcrumb-item">{t("c37")}</div>
                            <div className='next'>&gt;</div>
                            <div class="breadcrumb-item">{t("c40")}</div>
                        </div>
                    </div>
                    
</div>

<div className='deposit-background'>
<div className="deposit_panel_main">

    <div className='deposit_panel_main_first'>
        <h3>{t("c2")}</h3>
        <h1 className='deposit_panel_main_first_sum'>{deposit} $</h1>
        <div className='deposit_panel_main_first_text'>{t("c41")}</div>
        <div className='deposit_panel_main_first_op'>{t("c42")}</div>
        <div className='deposit_panel_main_button_block'>
            <div onClick={() => window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/deposit-funds`)} className='custom-button-icon'>
               <img className='logo-big' alt="pic" src={icon7} />
                <div>{t("c43")}</div>
            </div>
            <div onClick={() => window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client`)} className='custom-button-icon'>
               <img className='logo-big' alt="pic" src={icon7} />
                <div>{t("c44")}</div>
            </div>
            <div onClick={() => window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/withdraw`)} className='custom-button-icon'>
               <img className='logo-big' alt="pic" src={icon7} />
                <div>{t("c45")}</div>
            </div>
        </div>
    </div>


    <div className='deposit_panel_main_second'>
        <div className='deposit_panel_main_second_title'>{t("c46")}</div>

        <div className='deposit_panel_main_second_main'>
            <div className='deposit_panel_main_second_block'>
            <img className='logo-big-2x' alt="pic" src={icon7} />
                <div className='deposit_panel_main_second_inner'>
                    <div>{t("c47")}</div>
                    <span>{t("c48")}</span>
                </div>
            </div>
            <div className='block blue'>{allTimeCost} $</div>
        </div>

        <div className='deposit_panel_main_second_main'>
            <div className='deposit_panel_main_second_block'>
            <img className='logo-big-2x' alt="pic" src={icon4} />
                <div className='deposit_panel_main_second_inner'>
                    <div>{t("c49")}</div>
                    <span>{t("c50")}</span>
                </div>
            </div>
            <div className='block pink'>{allTimeCostWithdraw} $</div>
        </div>

        <div className='deposit_panel_main_second_main'>
            <div className='deposit_panel_main_second_block'>
            <img className='logo-big-2x' alt="pic" src={icon5} />
                <div className='deposit_panel_main_second_inner'>
                    <div>{t("c51")}</div>
                    <span>{t("c52")}</span>
                </div>
            </div>
            <div className='block green'>{activeDepositSum} $</div>
        </div>

        <div className='deposit_panel_main_second_main'>
            <div className='deposit_panel_main_second_block'>
            <img className='logo-big-2x' alt="pic" src={icon6} />
                <div className='deposit_panel_main_second_inner'>
                    <div>{t("c53")}</div>
                    <span>{t("c54")}</span>
                </div>
            </div>
            <div className='block purple'>{currentYieldFromReferal} $</div>
        </div>



    </div>
</div>
</div>
<div className='deposit_bottom_panel'>
    <h2>{t("r34")}</h2>
    <div className='deposit_bottom_panel_table'>
        {depositArray.length === 0 ? 
        <span>{t("r35")}</span> :
        <DepositTable data={depositArray}/>
    }
        
    </div>
</div>

            </div>
    )
}

export default DepositPanel;