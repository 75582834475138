


import React,{useState} from 'react';
import { useDispatch } from 'react-redux';

import { missingPassword } from '../../api/user';
import { useTranslation } from "react-i18next";

import { loginUser } from '../../api/user';

import "./Styles.css"

import Img from '../../image/shield.png';



 const ChangePassword = () => {


    const [email, setEmail] = useState();
    const [isSuccessSendRecoveryMail, setIsSuccessSendRecoveryMail] = useState('');
    const { t } = useTranslation();



    const onClick = () => {

        missingPassword(email.trim()).then(data => {
             console.log(data);
             if(data.message) {
              setIsSuccessSendRecoveryMail(data.message)
             }
        }).catch(e => {
            console.log(e);
        })
       
     }
 
  console.log("MODAL");
  return (
    <>  
    {
      isSuccessSendRecoveryMail.length != 0 ? 
(    <div className='modal-main'>
<div className='form-container'>
<img className='logo-big-2x' src={Img} />
  <h1>Готово!</h1>
  <h4>{isSuccessSendRecoveryMail}</h4>
</div>
</div>)
      :
(
  <div className='modal-main'>
  <div className='form-container'>
  <img className='logo-big-2x' src={Img} />
    <h1>Восстановление учетной записи</h1>
    <h4>Введите ваш адресс электронной почты на который будет отправлено письмо с восстановлением пароля.</h4>
    <div className='change-password'>
    <input className="changeInput" onChange={(e) => setEmail(e.target.value)}/>
    <button type="submit" onClick={onClick} className='btn-sok'>Отправить</button>
    </div>

</div>
</div>
)
    }
  
    </>
  );
};

export default ChangePassword;
