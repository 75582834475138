import React from 'react';
import { useDispatch } from 'react-redux';

import { setIsLoginTrue } from '../../store/login/actions';
import { useTranslation } from "react-i18next";

import "./Styles.css"

import Img from '../../image/shield.png';



 const CompleteModal = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();



    const onClick = () => {
        dispatch(setIsLoginTrue());
        window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client`);
       
     }
 
  console.log("MODAL");
  return (
    <>  
      <div className='modal-main'>
        <div className='form-container'>
        <img className='logo-big' src={Img} />
          <h1>{t("rgc")}</h1>
          <h4>На ваш адресс электронной почты отправлено письмо с подтверждением регистрации.</h4>
          <button type="submit" onClick={onClick} className='btn'>{t("rgc2")}</button>
      </div>
      </div>
    </>
  );
};

export default CompleteModal;
