import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';


import btc from '../../image/bitcoin.png';
import payeer from '../../image/payeer.png';
import trx from '../../image/trx.png';
import trxUsdt from '../../image/trx-usdt.png';
import busd from '../../image/busd.png';
import swift from '../../image/swift.png';
import icon4 from '../../image/icon4.png';



import { createDepositRequest, getDepositAddress } from '../../api/user';


import "./Styles.css"
import { t } from 'i18next';


const logoObg = {
    btcLogo: btc,
    trxLogo: trx,
    busdLogo: busd,
    trxUsdt: trxUsdt,
    swift: swift,
    payeer: payeer
}





const DepositPanel = () => { 

    const [currentDepositMethod, setCurrentDepositMethod] = useState("");
    const [currentIcon, setCurrentIcon] = useState();
    const [sumDeposit, setSumDeposit] = useState(0);
    const [depositAddressArray, setDepositAddressArray] = useState([]);
    const [currentAddress, setCurrentAddress] = useState('');
    const [copied, setCopied] = useState(false);

    const [isOpenModalDepositRequest, setIsOpenModalDepositRequest] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        getDepositAddress().then(data => {
            setDepositAddressArray(data.addressList);
        }).catch(e => {
            console.log(e);
        })
    },[])

    const createDepositRequestHandler = () => {
        const email = localStorage.getItem("login");
        const token = localStorage.getItem("token");
        console.log(currentDepositMethod);
        console.log(sumDeposit);
        
        createDepositRequest(token, {email, currentDepositMethod, sumDeposit}).then(data => {
            console.log(data);
            setIsOpenModalDepositRequest(false);
            window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/deposit-funds-history`);
        })

    }

    const closeModalHandler = () => {
        setIsOpenModalDepositRequest(false);
        setCurrentIcon();
        setSumDeposit();
        setCopied(false);
    }

    const saveMethodAndOpenModal = (method, icon, address) => {
        setCurrentAddress(address);
        setCurrentDepositMethod(method);
        setIsOpenModalDepositRequest(true);
        setCurrentIcon(icon); 
        window.scrollTo({
            top: 0,
            left: 100,
            behavior: 'smooth'
          });
    }

    const onCopy = () => {
        setCopied(true);
    }

    return (
        <>
        <div className="clientPanel">

<div class="row page-titles">
                    <div class="flex-boxx">   
					   <h3 class="text-themecolor">{t("c55")}</h3>
					   
                        <div class="breadcrumb">
                            <div class="breadcrumb-item">{t("c36")}</div>
                            <div className='next'>&gt;</div>
                            <div class="breadcrumb-item">{t("c37")}</div>
                            <div className='next'>&gt;</div>
                            <div class="breadcrumb-item">{t("c56")}</div>
                        </div>
                    </div>          
</div>



{ isOpenModalDepositRequest ? (
    <>  <Modal open={isOpenModalDepositRequest} onClose={closeModalHandler} center>
          <div className='modal-content_header'>
            <h2>{t("c92")}</h2>
        </div>

        <div className='modal-padding'>
        <img className='logo-big-3x' alt="pic" src={currentIcon} />
        <h4>{currentDepositMethod}</h4>


            <div className='modal-gray-panel'>
                <h4>{t("c91")}</h4>
                <div className='modal-gray-list'>
                    <div> {t("c57")}</div>
                    <div> {t("c93")}</div>
                    <div> {t("c94")}</div>
                </div>
                <div>{t("c95")}</div>
                {currentDepositMethod === "Swift" ?
                      <div> Не доступно для вашего региона </div> : 
                      <div className='modal-gray-limits'><div className='black_span'>{currentAddress}</div></div>
                      }
     
            </div>

            <CopyToClipboard 
            onCopy={onCopy}
          text={currentAddress}
          >

            <div className={`deposit_panel_main_second_ref_button `}>
            <img className='logo-big' alt="pic" src={icon4} />
                <div>Скопировать реквизиты</div>
            </div>
            </CopyToClipboard>
        {copied ? 
        <div className='copy-link'>
            Реквизиты скопированы
        </div>
        : ""}




     <div className='modal-form-panel'>
     <h4>{t("c96")}</h4>
     <input  type="number" placeholer="Введите сумму"  onChange={e => setSumDeposit(e.target.value)}/>
     </div>

     {/* <div className='modal-form-panel'>
     <h4 >Введите реквизиты:</h4>
     <input  type="text" placeholer="Введите сумму"  onChange={e => setAddressWithdraw(e.target.value)}/>
     </div> */}
    



    <button type="submit" onClick={() => createDepositRequestHandler()} className='btn-dep btn-dep-mod'>{t("c97")}</button>
        </div>

    
    <div className="modal-content-close">
        <div onClick={() => closeModalHandler()} className='custom-button-icon btn-custom '>
            <div>{t("c87")}</div>
        </div>
    </div>
  </Modal></>
) : <div></div>}






<div className='deposit-funds-panel-wrapper'>
<div className='deposit-funds-panel'>
    
   <div className='deposit-funds-panel-title'>{t("c57")}</div>
   <div className='deposit-funds-panel-list'>


    {
      depositAddressArray.length > 0 &&  depositAddressArray.map((item, index) => {


        return(
            <div key={index} className='deposit-funds-panel-item'>
            <div>
            <img className='logo-big-3x' alt="pic" src={logoObg[item.icon]} />
            </div>
            <h4>{item.method}</h4>
            {/* <span>{item.comission}</span> */}
            <div onClick={() => saveMethodAndOpenModal(item.method, logoObg[item.icon], item.address)} className='deposit-funds-panel-item-btn'>{t("c69")}</div>
        </div>
        )

        })
    }

   </div>
</div>

</div>




</div>










    </>
    )
}

export default DepositPanel;