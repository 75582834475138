
import React, { useEffect, useState } from 'react';
import   ReactTable  from "react-table";
import { TextField, Button } from "@material-ui/core";


import { getDepositAddress, changeAddressInMethod, changeQrInMethod } from '../../api/user';
// import "./Styles.css"


import "react-table/react-table.css";



 const AdminPanelAddress = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 

  const [isOpenModal, setIsOpenModal] = useState(false); 
  const [msgFromHood, setMsgFromHood] = useState(""); 

  const [currentMethod, setCurrentMethod] = useState(""); 


  const [newWallet, setNewWallet] = useState(""); 
  const [newQrCode, setNewQrCode] = useState(""); 



 

  useEffect(() => {

    getDepositAddress().then(data => {
        setData(data.addressList);
        console.log(data);
       })
  }, [currentPage])




  const openModalFunc = (method, header) => {
    setIsOpenModal(true);
    setCurrentMethod(method);
    setMsgFromHood(header);
  }



  const changeAction = () => {
    const token = localStorage.getItem("token");
    // setIsOpenModal(true);
    // setCurrentMethod(method);
    // setMsgFromHood(header);

    if(msgFromHood === "Введите новые реквизиты для метода оплаты") {
           changeAddressInMethod(token, currentMethod, newWallet).then(data => {
            setMsgFromHood(data.message);
    })} else {

        changeQrInMethod(token, currentMethod, newQrCode).then(data => {
            setMsgFromHood(data.message)
        })


    }


  }



 console.log(data);
  return (
    <>
    <div>
      <ReactTable
        data={data}
        columns={[
          {
            Header: "Метод",
            accessor: "method",
            // Cell: props => EditableCell({ ...props, updateCellData });
            // Cell: data?.depositOwner.login
          },
          {
            Header: "Реквизиты",
            accessor: "address",
            // Cell: data.email
          },
          {
            Header: "qrCodeURL",
            accessor: "qrCode",
            // Cell: props => EditableCell({ ...props, updateCellData })
            // Cell: data.deposit
          },
          {
            Header: "Поменять реквизиты",
            accessor: "id",
            Cell: props => (
              <Button onClick={() => openModalFunc(props.original.method,
                "Введите новые реквизиты для метода оплаты"
                )}>Сменить</Button>
            )
          },
          {
            Header: "Установить новый qrCode",
            accessor: "id",
            Cell: props => (
              <Button onClick={() => openModalFunc(props.original.method,
                "Введите ссылку на новый QR CODE"
                )}>Установить</Button>
            )
          }
        ]}

        showPagination={false}
        showPaginationBottom={true}
        className="-striped -highlight"
      >
        {/* {(state, makeTable, instance) => {
          console.log("UPDATE");
          return makeTable();
        }} */}
      </ReactTable>
      <br />


      <div className='pagination-panel'>
     
      </div>
    </div>
    {  isOpenModal ? 

//   <div className='adminModal'>
//     <div>
//     {msgFromHood}
//     {currentMethod}
//     <button type="submit" onClick={() => window.location.reload() } className='btn-dep'>ОК </button>
//     </div>
//    </div> 


  <div className='adminModal'>
    <div>
    <h1>{currentMethod}</h1>
    <h4>{msgFromHood}</h4>
    <div className='change-password'>

        {
            msgFromHood === "Введите новые реквизиты для метода оплаты" ?
            <input className="changeInput" onChange={(e) => setNewWallet(e.target.value)}/>
            :
            <input className="changeInput" onChange={(e) => setNewQrCode(e.target.value)}/>

        }
    <button type="submit" onClick={() => changeAction()} className='btn-sok'>Отправить</button>
    </div>

</div>
    </div>



   : null   }
    </>
  );
};

export default AdminPanelAddress;

