
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { createWithdrawRequest, getDepositSum } from '../../api/user';


import btc from '../../image/bitcoin.png';
import trx from '../../image/trx.png';
import payeer from '../../image/payeer.png';
import trxUsdt from '../../image/trx-usdt.png';
import busd from '../../image/busd.png';
import swift from '../../image/swift.png';

import "./Styles.css"


const Withdraw = () => { 

    const [currentWithdrawMethod, setCurrentWithdrawMethod] = useState("");
    const [sumWithdraw, setSumWithdraw] = useState(0);
    const [addressWithdraw, setAddressWithdraw] = useState("");
    const [isOpenModalWithdrawRequest, setIsOpenModalWithdrawRequest] = useState(false);
    const [currentIcon, setCurrentIcon] = useState();
    const [sumToWithdraw, setSumToWithdraw] = useState("")

    const { t } = useTranslation();

    useEffect(() => {

        const email = localStorage.getItem("login");
        const token = localStorage.getItem("token");
        getDepositSum(email, token).then(data => {
            console.log(data);
            setSumToWithdraw(data.deposit);

        }).catch(e => {
            console.log(e);
            if(e.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("login");
                window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`);
              };
        })
    }, [])

    const saveMethodAndOpenModal = (method, icon) => {
        setCurrentIcon(icon)
        setCurrentWithdrawMethod(method);
        setIsOpenModalWithdrawRequest(true);
        window.scrollTo({
            top: 0,
            left: 100,
            behavior: 'smooth'
          });
    }

    const createWithdrawRequestHandler = () => {
        const email = localStorage.getItem("login");
        const token = localStorage.getItem("token");
     
        createWithdrawRequest(token, {email, currentWithdrawMethod, sumWithdraw, addressWithdraw}).then(data => {
            console.log(data);
            setIsOpenModalWithdrawRequest(false);
            window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/withdraw-history`);
        })

    }

    const closeModalHandler = () => {
        setIsOpenModalWithdrawRequest(false);
        setSumWithdraw();
        setCurrentWithdrawMethod("");
    }

    return (
        <div className="clientPanel">

        <div class="row page-titles">
                            <div class="flex-boxx">   
                               <h3 class="text-themecolor">{t("c73")}</h3>
                               
                                <div class="breadcrumb">
                                    <div class="breadcrumb-item">{t("c36")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">{t("c37")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">{t("c74")}</div>
                                </div>
                            </div>          
        </div>


{ isOpenModalWithdrawRequest ? (
    <>  <Modal open={isOpenModalWithdrawRequest} onClose={closeModalHandler} center>
          <div className='modal-content_header'>
            <h2>{t("c75")}</h2>
        </div>

        

        <div className='modal-padding'>
        <img className='logo-big-3x' alt="pic" src={currentIcon} />
        <h4>{currentWithdrawMethod}</h4>


            <div className='modal-gray-panel'>
                <h4>{t("c76")}</h4>
                <div className='modal-gray-list'>
                    <div>{t("c77")}</div>
                    <div>{t("c78")}</div>
                    <div>{t("c78")}</div>
                    <div>  <span className='black_span'>{t("c79")}</span>{t("c80")}</div>
                </div>
                <div>{t("c81")}</div>
                <div className='modal-gray-limits'><span className='black_span'>{t("c82")}</span>{t("c83")}</div>
            </div>




     <div className='modal-form-panel'>
     <h4>{t("c84")}</h4>
     <input  type="number" placeholer="Введите сумму"  onChange={e => setSumWithdraw(e.target.value)}/>
     </div>

     <div className='modal-form-panel'>
     <h4 >{t("c85")}</h4>
     <input  type="text" placeholer="Введите сумму"  onChange={e => setAddressWithdraw(e.target.value)}/>
     </div>
    
    

   


    <button type="submit" onClick={() => createWithdrawRequestHandler()} className='btn-dep btn-dep-mod'>{t("c86")}</button>
        </div>

    
    <div className="modal-content-close">
        <div onClick={() => closeModalHandler()} className='custom-button-icon btn-custom '>
            <div>{t("c87")}</div>
        </div>
    </div>
  </Modal></>
) : <div></div>}



<div className='deposit-funds-panel-wrapper'>
<div className='deposit-funds-panel'>
<div className='deposit-funds-panel-title'>Доступно к выводу: {sumToWithdraw} $</div>
   <div className='deposit-funds-panel-title'>{t("c88")}</div>

   <div className='deposit-funds-panel-list'>


    <div className='withdraw-funds-panel-item'>
        <div>
        <img className='logo-big-3x' alt="pic" src={btc} />
        </div>
        <h4>Bitcoin</h4>

        <div onClick={() => saveMethodAndOpenModal('Bitcoin', btc)} className='withdraw-funds-panel-item-btn'>СОЗДАТЬ ЗАЯВКУ НА ВЫВОД В BITCOIN</div>
    </div>
    
    <div className='withdraw-funds-panel-item'>
    <div>
        <img className='logo-big-3x' alt="pic" src={trxUsdt} />
        </div>
        <h4>USDT TRC-20</h4>

        <div onClick={() => saveMethodAndOpenModal('USDT TRC-20', trxUsdt)}className='withdraw-funds-panel-item-btn '>СОЗДАТЬ ЗАЯВКУ НА ВЫВОД В USDT TRC-20</div>
    </div>
    <div className='withdraw-funds-panel-item'>
    <div>
        <img className='logo-big-3x' alt="pic" src={busd} />
        </div>
        <h4>BUSD BEP-20</h4>
 
        <div onClick={() => saveMethodAndOpenModal('BUSD BEP-20', busd)}className='withdraw-funds-panel-item-btn '>СОЗДАТЬ ЗАЯВКУ НА ВЫВОД В BUSD BEP-20</div>
    </div>
    <div className='withdraw-funds-panel-item'>
    <div>
        <img className='logo-big-3x' alt="pic" src={trx} />
        </div>
        <h4>TRON</h4>

        <div onClick={() => saveMethodAndOpenModal('TRON',trx)} className='withdraw-funds-panel-item-btn '>СОЗДАТЬ ЗАЯВКУ НА ВЫВОД В TRON</div>
    </div>
    <div className='withdraw-funds-panel-item'>
    <div>
        <img className='logo-big-3x' alt="pic" src={payeer} />
        </div>
        <h4>Payeer</h4>

        <div onClick={() => saveMethodAndOpenModal('Payeer', payeer)} className='withdraw-funds-panel-item-btn '>СОЗДАТЬ ЗАЯВКУ НА ВЫВОД В Payeer</div>
    </div>
    <div className='withdraw-funds-panel-item'>
    <div>
        <img className='logo-big-3x' alt="pic" src={swift} />
        </div>
        <h4>Swift</h4>

        <div onClick={() => saveMethodAndOpenModal('Swift', swift)} className='withdraw-funds-panel-item-btn '>СОЗДАТЬ ЗАЯВКУ НА ВЫВОД В Swift</div>
    </div>

   </div>
</div>

</div>

        </div>
    )
}


    export default Withdraw;