import React, { useEffect, useState } from 'react';
import { securityCheckChangePasswordByUser, sendNewPasswordByClient } from '../../api/user';

import { useLocation } from 'react-router-dom';
import Img from '../../image/shield.png';

import NotFound from '../NotFound/NotFound';




// import "./Styles.css"





 const ChangePasswordElement = () => {

  const { search, pathname } = useLocation();
     const [isAcccess, setIsAccess] = useState();
     const [emailToRecovery, setEmailToRecovery] = useState();
     const [recoveryPassword, setRecoveryPassword] = useState();
     const [currentCode, setCurrentCode] = useState();
     const [isSuccessRecovery, setIsSuccessRecovery] = useState("");

     useEffect(() => {
 

      const email = search.match(/\?email=.+?code=/)[0].slice(7,-6)
      const code = search.match(/\?code=.+/)[0].slice(6);
console.log(email);
console.log(code);

          securityCheckChangePasswordByUser(email, code).then(data => {
            console.log(data);
            setEmailToRecovery(email);
            setCurrentCode(code);
            setIsAccess(true);
          }).catch(e => {
            console.log(e)
          })
 
     },[pathname])


     const sendRecoveryPassword = () => {
        sendNewPasswordByClient(emailToRecovery, recoveryPassword, currentCode).then(data => {
          console.log(data)
          setIsSuccessRecovery(data.message)
        }).catch(e => {
          console.log(e);
        })
     }

 
  return (
    <>
       {!isAcccess ? 
      <NotFound />
      :
      isSuccessRecovery.length !== 0 ?


      <>
       <div className='modal-main'>
        <div className='form-container'>
        <img className='logo-big-3x' src={Img} />
          <h1>Пароль успешно обновлен!</h1>
          <h4>{emailToRecovery}</h4>
          <div className='change-password'>
          <button type="submit" onClick={() => window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`)} className='btn-sok'>Войти</button>
          </div>
 
      </div>
      </div>
      </>
      :


      <>
       <div className='modal-main'>
        <div className='form-container'>
        <img className='logo-big-3x' src={Img} />
          <h1>Введите новый пароль для учетной записи</h1>
          <h4>{emailToRecovery}</h4>
          <div className='change-password'>
          <input className="changeInput"  onChange={(e) => setRecoveryPassword(e.target.value)}/>
          <button type="submit" onClick={sendRecoveryPassword} className='btn-sok'>Сохранить пароль</button>
          </div>
 
      </div>
      </div>
      </>
      } 
    </>
  );
};

export default ChangePasswordElement;
