import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// import { arrayOf, element, func, number, oneOfType, shape, string } from 'prop-types';

import "./Styles.css"


import icon9 from '../../image/icon9.png';
import icon2 from '../../image/icon2.png';
import icon3 from '../../image/icon3.png';
import icon4 from '../../image/icon4.png';
import icon5 from '../../image/icon5.png';
import icon6 from '../../image/icon6.png';
import icon7 from '../../image/icon7.png';
import icon8 from '../../image/icon8.png';






function MenuModal() {

  const { t } = useTranslation();


  const redirectTo = (link) => {
    console.log("redirect");
      window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/${link}`);
  };


  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`);
  }

    return (
        <ul className="m-menu__list">

          <li onClick={() => redirectTo("client/deposit")} className="m-menu__item">
        <img className='logo-big' alt="pic" src={icon2} />
          <div className="m-menu__link">{t("Аккаунт")}</div>
        </li>

        <li onClick={() => redirectTo("client")} className="m-menu__item">
        <img className='logo-big' alt="pic" src={icon2} />
          <div className="m-menu__link">{t("c38")}</div>
        </li>

        {/* <li onClick={() => redirectTo("client/deposit")}  className="m-menu__item">
        <img className='logo-big' alt="pic" src={icon3} />
          <div className="m-menu__link">{t("r45")}</div>
        </li> */}

        <li onClick={() => redirectTo("client/deposit-funds")} className="m-menu__item green-item">
        <img className='logo-big logo-green' alt="pic" src={icon4} />
          <div className="m-menu__link">{t("r38")}</div>
        </li>
{/* 
        <li onClick={() => redirectTo("client/deposit-funds-history")} className="m-menu__item">
        <img className='logo-big' alt="pic" src={icon5} />
          <div className="m-menu__link">{t("c71")}</div>
        </li> */}

        <li onClick={() => redirectTo("client/withdraw")} className="m-menu__item">
        <img className='logo-big' alt="pic" src={icon6} />
          <div className="m-menu__link">{t("c45")}</div>
        </li>

        {/* <li onClick={() => redirectTo("client/withdraw-history")} className="m-menu__item">
        <img className='logo-big' alt="pic" src={icon7} />
          <div className="m-menu__link">{t("r40")}</div>
        </li> */}

        <li onClick={() => redirectTo("client/partner")} className="m-menu__item">
        <img className='logo-big' alt="pic" src={icon8} />
          <div className="m-menu__link">{t("c98")}</div>
        </li>

         <li onClick={() => redirectTo("client/withdraw-history")} className="m-menu__item">
        <img className='logo-big' alt="pic" src={icon7} />
          <div className="m-menu__link">{t("Операции")}</div>
        </li>

         <li onClick={() => redirectTo("client/settings")} className="m-menu__item">
        <img className='logo-big' alt="pic" src={icon7} />
          <div className="m-menu__link">{t("Настройки")}</div>
        </li>

        <li onClick={() => logout()} className="m-menu__item">
        <img className='logo-big' alt="pic" src={icon3} />
          <div className="m-menu__link">{t("r43")}</div>
        </li>

      </ul>
    );
}


export default MenuModal;
