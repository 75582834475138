import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


import { ColorRing } from 'react-loader-spinner'
import NotFound from "../NotFound/NotFound";
import "./Styles.css"





 const Error = () => {
  console.log("Error");
  const [is404, setIs404]= useState(false)
  const[isLoading, setIsLoading]= useState(true);
  const { pathname } = useLocation();

    useEffect(() => {
   
        if(pathname === "/client"  ||
        pathname === "/client/deposit" ||
        pathname === "/client/deposit-funds" ||
        pathname === "/client/deposit-funds-history"||
        pathname === "/client/withdraw" ||
        pathname === "/client/withdraw-history" ||
        pathname === "/client/partner" ||
        pathname === "/client/partner-all" 
        )  {
            setIs404(false)
        } else {
            setIsLoading(false);
            setIs404(true)
        }

    }, [])


  return (
    <>
    {
     isLoading ? 
      <NotFound />
     :
     is404 ?
       (
        <div className='notFound'>
        <div className='errorblock'>
            <div className='error_error'>404</div>
            <div className='errorcontent'>Страница не найдена</div>
            <div className='errorcontent'>Возможно она была удалена или перенесена на другой адрес.</div>
        </div>
        </div>
       )
     :

     <NotFound/>

    }

    </>
  );
};

export default Error;