import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import   ReactTable  from "react-table";
import "./Styles.css"
import "react-table/react-table.css";



 const DepositTable = ({data}) => {

  const { t } = useTranslation();

 console.log(data);
  return (
    <>
    <div>
      <ReactTable
        data={data}
        columns={[
          {
            Header: t("r47"),
            accessor: "depositSum",
            Cell: data.depositSum
          },
          {
            Header: "%",
            accessor: "depositProcent",
            Cell: data.depositProcent
          },
          {
            Header: t("r48"),
            accessor: "depositDays",
            Cell: data.depositDays
          },
          {
            Header: t("r49"),
            accessor: "totalSum",
            Cell: data.totalSum
          },
          {
            Header: t("r50"),
            accessor: "depositEndDate",
            Cell: data.depositEndDate
          },
          {
            Header: t("r51"),
            accessor: "isAvailable",
          },
        ]}

        showPagination={false}
        showPaginationBottom={true}
        defaultPageSize={data.length}
        className="-striped -highlight"
      >
        {/* {(state, makeTable, instance) => {
          console.log("UPDATE");
          return makeTable();
        }} */}
      </ReactTable>
      <br />
      {/* <div className='pagination-panel'>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev - 1)}>{`< НАЗАД`}</div>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev + 1)}>{`ВПЕРЕД >`}</div>
      </div> */}
    </div>
    </>
  );
};

export default DepositTable;
