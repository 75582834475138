import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import galaxy from '../../image/galaxy.png';
import NotFound from "../NotFound/NotFound"


import { confirmationEmail } from '../../api/user';


import "./Styles.css"





 const ConfirmEmail = () => {
    const { search } = useLocation();
    const [isSuccess, setIsSuccess] = useState(false);


    useEffect(() => {
     if(search) {
        console.log(search);

        const email = search.match(/\?email=.+\?c=/)[0];
        const updEmail = email.slice(7, -3);
        console.log(updEmail);
        confirmationEmail(updEmail).then(data => {
            setIsSuccess(true);
        }).catch(e => {
            console.log(e);
            if(e) {
                window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`);
            }
        })
     }

    }, [search])

    const redirectTo = () => {
        window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client`)
    }


  return (
    <>
    {
        !isSuccess ?
        <NotFound />
        :
        (
            <div className='notFound'>
            <div className='errorblock'>
            <img className='galaxy_logo' src={galaxy} />
                <div className='success_email_text'>Вы подтвердили адресс электронной почты!</div>
                <button onClick={() => redirectTo()} type="submit"  className='btn'>На главную!</button>
            </div>
            </div>
        )
    }
    

    </>
  );
};

export default ConfirmEmail;