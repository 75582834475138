import React, { useEffect } from 'react';

import { createUseStyles, useTheme } from 'react-jss';
import { Column, Row } from 'simple-flexbox';

import CollapsibleContent from '../CollapsibleContent/CollapsibleContent';
import { useSidebar } from '../../hooks/useSidebar';


const useStyles = createUseStyles({
    activeContainer: {
        backgroundColor: 'rgba(221, 226, 255, 0.1)'
    },
    container: {
        display: 'flex',
        height: 56,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(221, 226, 255, 0.1)'
        },
        // paddingLeft: ({ level }) => 18 * level,
        padding: "4px 15px",
        transition: 'all 0.2s ease-in-out',

    },
    leftBar: {
        borderLeft: ({ level }) =>
            level > 1 ? 'none' : '#A4A6B3',
          
    },
    title: {
        fontSize: 16,
        lineHeight: '20px',
        letterSpacing: '0.2px',
        color: ({ isActive }) => (isActive ? '#DDE2FF' : '#A4A6B3'),
        marginLeft: 24,
    }
});





function MenuItemComponent({ children, icon: Icon, id, items = [], level = 1, onClick, title }) {
    const theme = useTheme();
    const isCollapsible = children && children.length > 0;
    const { isExpanded, isActive, onItemClick } = useSidebar({
        isCollapsible,
        item: id,
        items
    });
    const classes = useStyles({ theme, level, isActive });
    const classNameColumn = isActive ? classes.leftBar : '';
    const classNameContainer = [classes.container, isActive && classes.activeContainer].join(' ');
    const iconColor = isActive ? '#DDE2FF' : '#9fa2b4';
    const height = '25px'

    function onItemClicked(e) {
        if (onClick) {
            onClick(e);
        }
        onItemClick();
    }

  useEffect(() => {
    console.log(isActive)
  }, [isActive])

    return (
  <>
        <Column key={id} className={classNameColumn}>
        <Row vertical='center' onClick={onItemClicked} className={classNameContainer}>
            {/* <Icon fill={iconColor} opacity={!isActive && '0.4'} /> */}
            {Icon ? (   <img  style={{height: '20px', width: '20px'}} src={Icon} />) : null}
         
            <span className={classes.title}>{title}</span>
        </Row>
        {isCollapsible && (
            <CollapsibleContent expanded={isExpanded}>
                {children.map((child) => child.type({ ...child.props }))}
            </CollapsibleContent>
        )}
    </Column>
    </>
    );
}



export default MenuItemComponent;
