import React from 'react';
import   ReactTable  from "react-table";

// import "./Styles.css"
import "react-table/react-table.css";



 const RefsTable = ({data}) => {


 console.log(data);
  return (
    <>
    <div>
      <ReactTable
        data={data}
        columns={[
          {
            Header: "Логин",
            accessor: "login",
          },
          {
            Header: "Пополнил",
            accessor: "allTimeDeposit",
          },
          {
            Header: "Вознаграждение",
            accessor: "profit",
          },
        ]}

        showPagination={false}
        showPaginationBottom={true}
        defaultPageSize={data.length}
        className="-striped -highlight"
      >
        {/* {(state, makeTable, instance) => {
          console.log("UPDATE");
          return makeTable();
        }} */}
      </ReactTable>
      <br />
      {/* <div className='pagination-panel'>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev - 1)}>{`< НАЗАД`}</div>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev + 1)}>{`ВПЕРЕД >`}</div>
      </div> */}
    </div>
    </>
  );
};

export default RefsTable;
