import React, { useEffect, useState } from 'react';
import NotFound from '../NotFound/NotFound';

import "./Styles.css"



 const Homepage = () => {

  useEffect(() => {
     const token = localStorage.getItem("token");
     if(token) {
      window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client`);
     } else {
      window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`);
     }
  },[])

  return (
    <>
    <NotFound />
    </>
  );
};

export default Homepage;