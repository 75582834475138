
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  login: Yup.string()
    .required('Обязательное поле'),
  email: Yup.string().email('Неправильный адрес почты').required('Обязательное поле'),
  password: Yup.string().required('Обязательное поле'),
  confirmpassword: Yup.string().oneOf([Yup.ref('password'), null], 'Пароли не совпадают'),
  name: Yup.string()
  .min(3, 'Должен содержать более 3 символов')
  .required('Обязательное поле'),
  surname: Yup.string()
    .min(3, 'Должен содержать более 3 символов')
    .required('Обязательное поле'),

  
terms: Yup.boolean().oneOf([true],'Обязательное условие')
});



export const validationLoginSchema = Yup.object().shape({

  email: Yup.string().email('Неправильный адрес почты').required('Обязательное поле'),
  password: Yup.string().required('Обязательное поле'),

});
