import React, { useEffect, useState } from 'react';
import   ReactTable  from "react-table";
import { TextField, Button } from "@material-ui/core";


import { getAllClients, deleteClientByAdmin, handleChangePasswordByAdmin} from '../../api/user';
// import "./Styles.css"


import "react-table/react-table.css";






 const AdminPanelCustomer = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 

  const [isOpenModal, setIsOpenModal] = useState(false); 
  const [isOpenModalPassword, setIsOpenModalPassword] = useState(false); 


  const [newPassword, setNewPassword] = useState(""); 

const [msgFromHood, setMsgFromHood] = useState(""); 

const [emailToDelete, setEmailToDelete] = useState("");
const [emailToChangePassword, setEmailToChangePassword] = useState("")



const openModalFunc = (emailToDelete) => {
  setIsOpenModal(true);
  // setCurrentMethod(method);
  setEmailToDelete(emailToDelete);
}
 

const openChangePasswordModalFunc = (email) => {
   setIsOpenModalPassword(true);
   setEmailToChangePassword(email);
}

  useEffect(() => {
    console.log("currentPage:");
    console.log(currentPage);
       const token = localStorage.getItem("token");
       getAllClients(token, {currentPage: currentPage}).then(data => {
        setData(data);
        console.log(data);
       })
  }, [currentPage])




  const closeDepositHandle = (email) => {
    const token = localStorage.getItem("token");

    deleteClientByAdmin(token, {email}).then(data => {
        console.log(data);
        setMsgFromHood(data.message);
        setIsOpenModal(false);
        window.location.reload();
    })
  }



  const changePasswordByAdmin = () => {

    const token = localStorage.getItem("token");

    
    handleChangePasswordByAdmin(token, {newPassword, emailToChangePassword}).then(data => {
      console.log(data);
      setMsgFromHood(data.message);
      setIsOpenModalPassword(false);
      window.location.reload();
  })
    // console.log(newPassword);
    // console.log(emailToChangePassword);
  }


 console.log(data);
  return (
    <>
    <div>
      <ReactTable
        data={data}
        columns={[
          {
            Header: "Логин",
            accessor: "login",
            // Cell: props => EditableCell({ ...props, updateCellData });
            // Cell: data?.depositOwner.login
          },
          {
            Header: "Email",
            accessor: "email",
            // Cell: data.email
          },
          {
            Header: "Рефералы",
            accessor: "refs",
            // Cell: data.email
          },
          {
            Header: "Баланс пользователя",
            accessor: "deposit",
            // Cell: props => EditableCell({ ...props, updateCellData })
            // Cell: data.deposit
          },
          {
            Header: "Общая сумма пополнений",
            accessor: "allTimeDeposit",
            // Cell: props => EditableCell({ ...props, updateCellData });
            Cell: data.depositSum
          },
          {
            Header: "Общая сумма вывода",
            accessor: "allTimeWithdraw",
            // Cell: props => EditableCell({ ...props, updateCellData });
            Cell: data.depositProcent
          },
  

          {
            Header: "Имя",
            accessor: "name",
            Cell: data.isAlive
            // Cell: props => EditableCell({ ...props, updateCellData })
          },
          {
            Header: "Фамилия",
            accessor: "surname",
            Cell: data.isAlive
            // Cell: props => EditableCell({ ...props, updateCellData })
          },
          {
            Header: "Telegram",
            accessor: "telegram",
            Cell: data.isAlive
            // Cell: props => EditableCell({ ...props, updateCellData })
          },
          {
            Header: "Удалить пользователя",
            accessor: "id",
            Cell: props => (
              <Button onClick={() => openModalFunc(props.original.email
                )}>Удалить</Button>
            )
          },
          {
            Header: "Пароль",
            accessor: "password",
            Cell: data.isAlive
          },
          {
            Header: "Сменить Пароль",
            accessor: "id",
            Cell: props => (
              <Button onClick={() => openChangePasswordModalFunc(props.original.email
                )}>Сменить пароль</Button>
            )
          },
        ]}

        showPagination={false}
        showPaginationBottom={true}
        className="-striped -highlight"
      >
        {/* {(state, makeTable, instance) => {
          console.log("UPDATE");
          return makeTable();
        }} */}
      </ReactTable>
      <br />
      <div className='pagination-panel'>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev - 1)}>{`< НАЗАД`}</div>
        <div className='pagination-panel_page'>{currentPage}</div>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev + 1)}>{`ВПЕРЕД >`}</div>
      </div>

      <div className='pagination-panel'>
     
      </div>
    </div>
    {  isOpenModal ? 
  <div className='adminModal'>
    <div>
    {`Вы действительно хотите удалить пользователя ${emailToDelete}`}
    <button type="submit" onClick={() => closeDepositHandle(emailToDelete) } className='btn-dep'>УДАЛИТЬ</button>
    <button type="submit" onClick={() => window.location.reload() } className='btn-dep'>Закрыть окно</button>
    </div>
   </div> 
   : null   }

{  isOpenModalPassword ? 
  <div className='adminModal'>
    <div>
    {`Введите новый пароль для пользователя ${emailToChangePassword}`}
    <input className="changeInput" onChange={(e) => setNewPassword(e.target.value)}/>
    <button type="submit" onClick={() => changePasswordByAdmin() } className='btn-dep'>УСТАНОВИТЬ ПАРОЛЬ</button>
    <button type="submit" onClick={() => window.location.reload() } className='btn-dep'>Закрыть окно</button>
    </div>
   </div> 
   : null   }


    </>
  );
};

export default AdminPanelCustomer;



