import React, { useEffect, useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { registerUser, loginUser } from '../../api/user';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// import i18n from "../../utils/i18n"


// import { setIsLoginTrue } from '../../store/login/actions';

import ParticlesComponent from "../../components/Particles";
import {formValues}  from "../../utils/formData"
import {validationSchema} from "../../utils/validationSchema"
import CompleteModal from "../../components/CompleteModal/CompleteModal"
import NotFound from '../NotFound/NotFound';
import eye from '../../image/eye.png';
import backpic from '../../image/back_new.jpg';



import "./Styles.css"





 const CreateAccount = () => {
  const { search } = useLocation();  
  const [error, setError] = useState('');
  const [isModal, setIsModal] = useState(false);
  const [isSetToken, setToken] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(true)

  const { t, i18n } = useTranslation();

  // const changeLanguage = lng => {
  //   if(lng === 'RU') {
  //     i18n.changeLanguage('ru-RU');
  //   } else {
  //     i18n.changeLanguage(lng);
  //   }
  // };

  useEffect(() => {
    const isToken = localStorage.getItem("token");
    if(isToken) {
        window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client`);
    } else {
      setToken(true);
    }
 
  }, [])
  


    const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
     
     console.log(values);     
     let newValues = values;
     if(search){
      const whoInvite = search.slice(5)
       newValues = {...values, whoInvite}
       console.log(newValues);
     }
 
     try {
      console.log(newValues);
      debugger;
      registerUser(newValues).then(data => {

   if(data.message) {
    return setError(data.message);
   }

        if (!data.customerNo) {
          setError(Object.values(data).toString());
        } else {
  
          loginUser(values).then((userData) => {
    
            if (userData.token) {
              const { token } = userData;
              const currentToken = token.replace(/Bearer /i, '');
              localStorage.setItem('token', currentToken);
              localStorage.setItem('login', values.email);
             
   
              setIsModal(true);
          
            } else {
              setError(Object.values(userData).toString());
            }
          });
  
        }
        // console.log(data);
        
      })
     } catch(e) {
      console.log(e);
     }
     }


  return (
    <>

    { isSetToken ? 
      <>
       {/* <ParticlesComponent /> */}
       <div className='bckgrnd_pic'>
       <img className="bckgrnd_picture" src={backpic} alt=""  />
       </div>
     
     { isModal
      ? (<CompleteModal />)
      :( <div className='form-div'>
         <div className='form-container'>
 
 {/* <div className='select-box'>
 <select  className='custom-select' onChange ={(e) => changeLanguage(e.target.value)} >  
 <option selected={localStorage.getItem("i18nextLng") === "ru-RU" ? "selected"  :  ""} className='custom-option'>RU</option>  
 <option selected={localStorage.getItem("i18nextLng") === "EN" ? "selected"  :  ""} className='custom-option'>EN</option>  
 </select>  
 </div> */}
 
 
 
 
           <h1>{t("reg")}</h1>
           <p class="signup-link register">{t("alr")}<a href="/login">{t("auth")}</a></p>
       
       
       
       <Formik
         initialValues={{
           login: '',
           email: '',
           password: '',
           name: '',
           surname: '',
           telegram: '',
           terms: false
         }}
         validationSchema={validationSchema}
         onSubmit={handleFormSubmit}
       >
         
         {(errors, touched, ) => {
           return (
 
             <Form>
 
                 {formValues.map(({ id, name, type, placeholder, label, d, cx, cy, r }) => (
                   <div key={id} className='field-container'>
 
                     <label htmlFor={name} className="form-label">{t(label)}</label>
                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                       <path d={d}></path>
 
                       {placeholder === "*****" ? (
                         <rect x={cx} y={cy} width="18" height="11" rx="2" ry="2"></rect>
                       ) : (
                             <circle cx={cx} cy={cy} r={r}></circle>
                       )}

                       </svg>
 

                       {placeholder === "*****" ? (
                           <Field
                           type={!hiddenPassword ? "text" : "password"}
                           id={name}
                           name={name}
                           placeholder={t(placeholder)}
                           className="custom-field"
             
                         />
                       ) 
                       : 
                       (   
                       <Field
                        id={name}
                        name={name}
                        placeholder={t(placeholder)}
                        className="custom-field"
          
                      />)
                       }
            
                       <div className='error-wrapper'>
                       <ErrorMessage name={name} />
                       </div>
                       {error && name === "email" ? <div className='error-wrapper'>{error}</div> : null}
                     {placeholder === "*****" ?  
                     <div onClick={() => setHiddenPassword((prevState) => !prevState)} className='hiddenPswrd'>
                       <img className='logo-eye' alt="pic" src={eye} />
                     </div> 
                     : null}
          
                   </div>
                 ))}
                  <div className="checkbox-box">
             <Field type="checkbox"  name="terms" />
             <span>{t("f1")} <a target="_blank" href="/?page=rules">{t("f2")}</a> {t("f3")}</span> 
             <div className='error-wrapper-checkbox'>
                       <ErrorMessage name="terms" />
                       </div>
           </div>
 
 
               
                 <button type="submit" className='btn'>{t("rg")}</button>
 
             </Form>
           );
         }}
       </Formik>
       </div>
       </div>)}
      </>
  :

  <NotFound />
  }

  
   
    </>
  );
};

export default CreateAccount;
