import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ColorRing } from 'react-loader-spinner'






 const Redirect = () => {
  const { pathname } = useLocation();

    useEffect(() => {
      const isToken = localStorage.getItem("token");
      if(isToken && (pathname === "/client" )) {
        // console.log(pathname);
        window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/adminPanel`);
      }
    }, [])


  return (
    <>
      <div className='notFound'>
      <ColorRing
  visible={true}
  height="210"
  width="210"
  ariaLabel="blocks-loading"
  wrapperStyle={{}}
  wrapperClass="blocks-wrapper"
  colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
/>
      </div>
    </>
  );
};

export default Redirect;