
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getAllRefs } from '../../api/user';
import RefsTable from '../../components/RefsTable/RefsTable';

import "./Styles.css"


const PartnerAll = () => { 
    const [refList, setRefList] = useState([]);

    useEffect(() => {
        const email = localStorage.getItem("login");
        const token = localStorage.getItem("token");

       getAllRefs( token, email).then(data => {
        setRefList(data.referals)
       }).catch(e => {
        console.log(e)
       })
    }, [])

    const { t } = useTranslation();
    
    return (
        <div className="clientPanel">

        <div class="row page-titles">
                            <div class="flex-boxx">   
                               <h3 class="text-themecolor">{t("c98")}</h3>
                               
                                <div class="breadcrumb">
                                    <div class="breadcrumb-item">{t("c36")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">{t("c37")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">{t("c98")}</div>
                                </div>
                            </div>          
        </div>

        <div className='deposit_bottom_panel'>
    <h2>{t("r32")}</h2>
    <div className='deposit_bottom_panel_table'>
        {refList.length === 0 ? 
        <span> {t("r333")}</span> :
        <RefsTable data={refList}/>
    }
        
    </div>

</div>

        </div>
    )
}


    export default PartnerAll;