import React, { useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import useSound from 'use-sound';
import {
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  MessageList,
  MessageInput,
  Thread,
  Window,
} from 'stream-chat-react';

import { getChatToken } from '../../api/chat';
import boopSfx from '../../image/msg.mp3';

import '@stream-io/stream-chat-css/dist/css/index.css';
// import '@stream-chat-react/dist/scss/v2/index.scss';
// import '@stream-chat-react/dist/css/v2/index.css';

const customClasses = {
  // channelList: 'str-chat__theme-dark str-chat__channel-list',
  //   channel: 'str-chat__theme-light str-chat__channel',
  chat: "str-chat__theme-dark"
};

const filters = { type: 'messaging', members: { $in: ['Client4000728'] } };
const options = { state: true, presence: true, limit: 10 };
const sort = { last_message_at: -1 };

const AdminPanelChat = () => {
  const [client, setClient] = useState(null);
  const [play] = useSound(boopSfx, { volume: 0.5 });
  const [isNotification, setIsNotification] = useState(true);

  useEffect(() => {
    play();
  }, [isNotification]);


  
  useEffect( () => {
       const func = async () => {
        const newClient = new StreamChat(process.env.REACT_APP_STREAM_API_SECRET);

        const handleConnectionChange = ({ online = false }) => {
          if (!online) return console.log('connection lost');
          setClient(newClient);
          return true;
        };
    
        newClient.on('connection.changed', handleConnectionChange);
    
        const name = localStorage.getItem('clientName');
    
        getChatToken(name).then(async (data) => {
          await newClient.connectUser(
            {
              id: name,
              name,
            },
            data,
          );
    
          newClient.on((event) => {
            if (event.total_unread_count !== null) {
              setIsNotification((prevState) => !prevState);
            }
    
            if (event.unread_channels !== null) {
              setIsNotification((prevState) => !prevState);
            }
          });
        });
    
        return () => {
          newClient.off('connection.changed', handleConnectionChange);
          newClient.disconnectUser().then(() => console.log('connection closed'));
        };



       }


       if(!client) {
        func();
       }

  }, []);

  if (!client) return null;

  return (
    <Chat client={client} customClasses={customClasses} theme='str-chat__theme-dark'>
      <ChannelList filters={filters} sort={sort} options={options} />
      <Channel>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};
// const AdminPanel = () => {
//   return <h1>Welcome to admin Panel</h1>;
// };

export default AdminPanelChat;
