import React, { useEffect, useState } from 'react';
import   ReactTable  from "react-table";
import { TextField, Button } from "@material-ui/core";


import { getDepositsByAdmin, closeDeposit } from '../../api/user';
import "./Styles.css"


import "react-table/react-table.css";






 const AdminPanel = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 

  const [isOpenModal, setIsOpenModal] = useState(false); 
const [msgFromHood, setMsgFromHood] = useState(""); 
 

  useEffect(() => {
    console.log("currentPage:");
    console.log(currentPage);
       const token = localStorage.getItem("token");
       getDepositsByAdmin(token, currentPage).then(data => {
        setData(data);
       })
  }, [currentPage])




  const closeDepositHandle = (email, totalDepositSum, profitSum, idDeposit, status) => {
    if(status === "Закрыт") {
      alert("Депозит уже закрыт")
      return;
    } else {

          // console.log(email);
    const token = localStorage.getItem('token');
    closeDeposit(token, {email, totalDepositSum, profitSum, idDeposit}).then(data => {
      // console.log(data);
      setMsgFromHood(data.message);
      setIsOpenModal(true);
    })

    }
  }



 console.log(data);
  return (
    <>
    <div>
      <ReactTable
        data={data}
        columns={[
          {
            Header: "Логин",
            accessor: "depositOwner.login",
            // Cell: props => EditableCell({ ...props, updateCellData });
            // Cell: data?.depositOwner.login
          },
          {
            Header: "Email",
            accessor: "depositOwner.email",
            // Cell: data.email
          },
          {
            Header: "Рефералы",
            accessor: "depositOwner.refs",
            // Cell: data.email
          },
          {
            Header: "Баланс пользователя",
            accessor: "depositOwner.deposit",
            // Cell: props => EditableCell({ ...props, updateCellData })
            // Cell: data.deposit
          },
          {
            Header: "Сумма депозита",
            accessor: "depositSum",
            // Cell: props => EditableCell({ ...props, updateCellData });
            Cell: data.depositSum
          },
          {
            Header: "%",
            accessor: "depositProcent",
            // Cell: props => EditableCell({ ...props, updateCellData });
            Cell: data.depositProcent
          },
          {
            Header: "Дней депозита",
            accessor: "depositDays",
            Cell: data.depositDays
            // Cell: props => EditableCell({ ...props, updateCellData })
          },
          {
            Header: "Сумма к выводу",
            accessor: "totalSum",
            Cell: data.totalSum
            // Cell: props => EditableCell({ ...props, updateCellData })
          },
          {
            Header: "Дата окончания",
            accessor: "depositEndDate",
            Cell: data.depositEndDate
            // Cell: props => EditableCell({ ...props, updateCellData })
          },
          {
            Header: "Статус",
            accessor: "isAvailable",
            Cell: data.isAlive
            // Cell: props => EditableCell({ ...props, updateCellData })
          },
          {
            Header: "Закрыть депозит",
            accessor: "id",
            Cell: props => (
              <Button onClick={() => closeDepositHandle(props.original.depositOwner.email, props.original.depositOwner.deposit, props.original.totalSum, props.original._id, props.original.isAvailable
                )}>Закрыть</Button>
            )
          }
        ]}

        showPagination={false}
        showPaginationBottom={true}
        className="-striped -highlight"
      >
        {/* {(state, makeTable, instance) => {
          console.log("UPDATE");
          return makeTable();
        }} */}
      </ReactTable>
      <br />
      <div className='pagination-panel'>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev - 1)}>{`< НАЗАД`}</div>
        <div className='pagination-panel_page'>{currentPage}</div>
        <div className='pagination-panel_button'onClick={() => setCurrentPage(prev => prev + 1)}>{`ВПЕРЕД >`}</div>
      </div>

      <div className='pagination-panel'>
     
      </div>
    </div>
    {  isOpenModal ? 
  <div className='adminModal'>
    <div>
    {msgFromHood}
    <button type="submit" onClick={() => window.location.reload() } className='btn-dep'>ОК </button>
    </div>
   </div> 
   : null   }
    </>
  );
};

export default AdminPanel;




