import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import useAuth from "./hooks/useAuth"
import { UseLogOutRoutes, UseClientRoutes, UseAdminRoutes } from './routes';
import Header from './components/Header';
import { setIsLoginFalse, setIsLoginTrue } from './store/login/actions';
import { checkAdmin } from './api/user';
import SidebarComponent from "./components/SidebarComponent/SidebarComponent"
import {SidebarContext} from "./hooks/useSidebar"
import NotFound from './pages/NotFound/NotFound';

import ChatClient from './components/ChatClient/ChatClient';



const wrapperWithClientHeader = ({children}) => {
  return(
    <>
    <Header />
    <SidebarComponent />
    {children}
    </>
  )
}

const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((data) => data.login.isLogin);
  const [admin, setAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
 
  // const {isAdmin = useAuth();

    const LogOutRoutes = UseLogOutRoutes();
        // const ClientRoutes = UseClientRoutes();


  useMemo(() => {
    const isLogin = !!localStorage.getItem('token');
    isLogin ? dispatch(setIsLoginTrue()) : dispatch(setIsLoginFalse());

    const email = localStorage.getItem('login');
    const token = localStorage.getItem('token');
    
    checkAdmin(email, token).then(res => {
      console.log(res);
      if(res.isAdmin) {
        
          setAdmin(true);
          setIsLoading(false);
      } else {
        console.log("USER");
            setIsLoading(false)
      }
  }).catch(e => {
    setIsLoading(false)
  })
  }, []);



  console.log(admin);
  console.log(isLoading);
  console.log(isLoggedIn);
  return (

<>

     
    <Router>

      {!isLoading ?
      
      !isLoggedIn ? 
        <div>
            <UseLogOutRoutes />
           {/* {process.env.REACT_APP_CHAT_MODERATOR ===
            localStorage.getItem('clientName') ? (
              <div>admin</div>
            ) : (
              <ChatClient />
         
            )} */}
        </div>
       : (
          admin ?   <UseAdminRoutes />  :   
          <>
               <UseClientRoutes /> 
             {/* {process.env.REACT_APP_CHAT_MODERATOR ===
            localStorage.getItem('clientName') ? (
              <div>admin</div>
            ) : (
              <ChatClient />

            )} */}
          </>
        )
        
    :
    

    <NotFound />
    }
        
    </Router>

    </>
    
  );
};



export default App;