
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {CopyToClipboard} from 'react-copy-to-clipboard';

import { referalInfo } from '../../api/user';

import "./Styles.css"

import icon7 from '../../image/icon7.png';
import icon3 from '../../image/icon3.png';
import icon4 from '../../image/icon4.png';
import icon5 from '../../image/icon5.png';
import icon6 from '../../image/icon6.png';


const Partner = () => { 

    const [nonActiveReferal, setNonActiveReferal] = useState(0);
    const [activeReferal, setActiveReferal] = useState(0);
    const [yieldFromReferal, setYieldFromReferal] = useState(0);
    const [allRefsCount, setAllRefsCount] = useState(0);
    const [culogin, setCuLogin] = useState("");
    const [copied, setCopied] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        const login = localStorage.getItem("login");
        const token = localStorage.getItem("token");
        setCuLogin(login);
        
       console.log(login, token);
        referalInfo(login, token).then(data => {
            if(data.referals.length > 0) {
                setNonActiveReferal(data.nonActiveReferal);
                setActiveReferal(data.activeReferal);
                setYieldFromReferal(data.yieldFromReferal)
                setAllRefsCount(data.referals.length);
            }
      
        }).catch(e => {
            if(e.response.status === 401) {
                localStorage.removeItem("token");
                localStorage.removeItem("login");
                window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`);
              };
        })
    }, [])


    const onCopy = () => {
        setCopied(true);
    }

    return (
        <div className="clientPanel">

        <div class="row page-titles">
                            <div class="flex-boxx">   
                               <h3 class="text-themecolor">{t("c98")}</h3>
                               
                                <div class="breadcrumb">
                                    <div class="breadcrumb-item">{t("c36")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">{t("c37")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">{t("c98")}</div>
                                </div>
                            </div>          
        </div>



<div className='deposit-background'>
<div className="deposit_panel_main">

    <div className='deposit_panel_main_first'>
        <h3>{t("c98")} Renaissance-Invest</h3>
        
        <div className='partner-box'>
            <div className='partner-blue-box'>
                <h4>{t("r10")}</h4>
                <h4 className='bold'>{t("r111")}</h4>
                <div className="partner-blue-icon-box">
                <img className='logo-big' alt="pic" src={icon7} />
                    <span>{t("r12")}</span>
                </div>
            </div>
        </div>

        <div className='partner-box-text-block'>{t("r13")}</div>

        <div className='partner-box-text-block'>
            <span className='span-gray-bolder'>{t("r14")} </span>
            <span className='span-green'>{t("r15")}</span>
        </div>

        <div className='partner-box-text-block'>
        <span className='span-gray-bolder'>{t("r16")}</span> {t("r17")}
        </div>

        <div className='partner-box-text-block'>
            <span className='span-gray-bolder'>{t("r18")}</span>
        </div>


        {/* <div className='partner-box'>
            <div className='partner-beige-box'>
                <h4>{t("r19")}</h4>
            </div>
        </div> */}

        <div className='partner-box last'>
            <div className='partner-beige-box'>
                <h4>{t("r31")}</h4>
            </div>
        </div>
    </div>


    <div className='deposit_panel_main_second'>
        <div className='deposit_panel_main_second_title'>{t("r20")}</div>

         <div className='deposit_panel_main_second_ref'>
            <div className='deposit_panel_main_second_ref_title'>{t("r21")}</div>
            <div className='deposit_panel_main_second_ref_link'>{`${process.env.REACT_APP_FRONTEND_URI}/create-account/?ref=${culogin}`}</div>
            
            
            <CopyToClipboard 
            onCopy={onCopy}
          options={{message: 'Whoa!'}}
          text={`${process.env.REACT_APP_FRONTEND_URI}/create-account/?ref=${culogin}`}
          >

            <div className={`deposit_panel_main_second_ref_button `}>
            <img className='logo-big' alt="pic" src={icon4} />
                <div>{t("r22")}</div>
            </div>
            </CopyToClipboard>
        {copied ? 
        <div className='copy-link'>
            Ссылка скопирована
        </div>
        : ""}
          
         </div>

        <div className='deposit_panel_main_second_main '>
            <div className='deposit_panel_main_second_block'>
            <img className='logo-big-2x' alt="pic" src={icon7} />
                <div className='deposit_panel_main_second_inner '>
                    <div>{t("r23")}</div>
                    <span>{t("r24")}</span>
                </div>
            </div>
            <div className='block blue icon-border'>{`${yieldFromReferal} $`}</div>
        </div>

        <div className='deposit_panel_main_second_main'>
            <div className='deposit_panel_main_second_block'>
            <img className='logo-big-2x' alt="pic" src={icon4} />
                <div className='deposit_panel_main_second_inner'>
                    <div>{t("r25")}</div>
                    <span>{t("r26")}</span>
                </div>
            </div>
            <div className='block-count  pink icon-border'>{`${allRefsCount}`}</div>
        </div>

        <div className='deposit_panel_main_second_main'>
            <div className='deposit_panel_main_second_block'>
            <img className='logo-big-2x' alt="pic" src={icon5} />
                <div className='deposit_panel_main_second_inner'>
                    <div>{t("r27")}</div>
                    <span>{t("r28")}</span>
                </div>
            </div>
            <div className='block-count icon-border green'>{`${allRefsCount}`}</div>
        </div>

        <div className='deposit_panel_main_second_main'>
            <div className='deposit_panel_main_second_block'>
            <img className='logo-big-2x' alt="pic" src={icon6} />
                <div className='deposit_panel_main_second_inner'>
                    <div>{t("r29")}</div>
                    <span>{t("r30")}</span>
                </div>
            </div>
            <div className='block-count purple icon-border'>{`${activeReferal}`}</div>
        </div>



    </div>
</div>
</div>
{/* <div className='deposit_bottom_panel'>
    <h2>Список открытых и закрытых депозитов</h2>
    <div>
        <span className='dataTime'>Дата и Время: 20 Ноября 03:29</span>
    </div>
</div> */}


        </div>
    )
}


    export default Partner;