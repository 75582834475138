import React, {useState, useRef, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import "./Styles.css"
import { createUseStyles, useTheme } from 'react-jss';
import { useClick } from '../../hooks/useClick';
import MenuModal from '../MenuModal/MenuModal';

import user from '../../image/user.png';


const useStyles = createUseStyles((theme) => ({
  avatar: {
      height: 35,
      width: 35,
      minWidth: 35,
      borderRadius: 50,
      marginLeft: 14,
      border: `1px solid red`,
      '@media (max-width: 768px)': {
          marginLeft: 14
      }
  },
  container: {
      height: 40
  },
  name: {
      // ...theme.typography.itemTitle,
      textAlign: 'right',
      '@media (max-width: 768px)': {
          display: 'none'
      }
  },
  separator: {
      borderLeft: `1px solid red`,
      marginLeft: 32,
      marginRight: 32,
      height: 32,
      width: 2,
      '@media (max-width: 768px)': {
          marginLeft: 14,
          marginRight: 0
      }
  },
  title: {
      // ...theme.typography.title,
      '@media (max-width: 1080px)': {
          marginLeft: 50
      },
      '@media (max-width: 468px)': {
          fontSize: 20
      }
  },
  iconStyles: {
      cursor: 'pointer',
      marginLeft: 25,
      '@media (max-width: 768px)': {
          marginLeft: 12
      }
  }
}));




const Header = () => {
  const isMobile = window.innerWidth <= 1080;


  const { t, i18n } = useTranslation();
  const menuRef = useRef(null);

  const contactMenuRef = useRef(null);

  const [listening, setListening] = useState(false);

  const [isOpen, setIsOpen] = useState(false);  
  const [isOpenContact, setIsOpenContact] = useState(false); 

  const login = localStorage.getItem("login");


  // useEffect(( ) => {
  //   const language = localStorage.getItem("i18nextLng");
  //   if(language === "ru-RU") {
  //     localStorage.setItem("i18nextLng", "RU") ;
  //   }
  // }, [

  // ])

  const toggle = () => setIsOpen(!isOpen);
  // const toggleNews = () => setIsOpenNews(!isOpenNews);
  // const toogleFeedback = () => setIsOpenFeedback(!isOpenFeedback);
  const toggleContact = () =>setIsOpenContact(!isOpenContact);

  useEffect(useClick(
    listening,
    setListening,
    menuRef,
    setIsOpen,
  ));


  useEffect(useClick(
    listening,
    setListening,
    contactMenuRef,
    setIsOpenContact,
  ));




// const changeLanguage = lng => {
//   if(lng === 'RU') {
//     i18n.changeLanguage('ru-RU');
//   } else {
//     i18n.changeLanguage(lng)
//   }

// };



  return (
    <>
    <div className='header'>
       
    

           <div className='boxheader'>

            {/* <div className='select-box'>
<select  className='custom-select-header' onChange ={(e) => changeLanguage(e.target.value)} >  
<option selected={localStorage.getItem("i18nextLng") === "ru-RU" ? "selected"  :  ""} className='custom-option'>RU</option>  
<option selected={localStorage.getItem("i18nextLng") === "EN" ? "selected"  :  ""} className='custom-option'>EN</option>  
</select>  
</div> */}


<div ref={menuRef} className={isOpen ? "m-menu -active" : "m-menu "}>
      <div className="header_item" onClick={toggle}>
        {/* {`Здравствуйте, ${login}`} */}
        {!isMobile ? 
        
        <div className='header_box'>
          <img className='logo-small-2x' alt="pic" src={user} />
          <span>{`${t("r44")}, ${login}`} </span>
        </div>
        
        : 
        
        <div>
          <img className='logo-small-2x' alt="pic" src={user} />
        </div>
        
        
       }
        </div>
      <MenuModal />
    
    </div>

    </div> 



    </div>

    </>
  );
};

export default Header;
