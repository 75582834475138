import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import useCollapse from 'react-collapsed';
import { Collapse } from "react-collapse";


import "./Styles.css"
import { Formik, Field, Form } from 'formik';

import { getDepositSum, createDeposit, deleteUser } from '../../api/user';


// import ok from '../../image/ok.png';
import ok from '../../image/t1.png';
import t2 from '../../image/t2.png';
import t3 from '../../image/t3.png';
import t4 from '../../image/t4.png';



const options = [
  {
    time: "Индекс '20 акций'",
    procent: 1.1,
    bonus: 0.32,
    date: "На 30 дней"
  },
  {
    time: "Индекс '35 акций'",
    procent: 1.4,
    bonus: 0.42,
    date: "На 30 дней"
  },
  {
    time: "Индекс '50 акций'",
    procent: 2.1,
    bonus: 1.2,
    date: "На 30 дней"
  }, 
  {
    time: "Поздние стадии развития",
    procent: 2.1,
    bonus: 2.3,
    date: "На 14 дней"
  },
  {
    time: "Средняя стадия развития ",
    procent: 2.6,
    bonus: 2.3,
    date: "На 14 дней"
  },
  {
    time: "Начальная стадия развития",
    procent: 3.2,
    bonus: 2.3,
    date: "На 14 дней"
  },
  {
    time: "Финтех, зеленая энергетика",
    procent: 42,
    bonus: 2.3,
    date: "На 7 дней"
  },
  {
    time: "Электронная комерция",
    procent: 54,
    bonus: 2.3,
    date: "На 7 дней"
  },  {
    time: "Кибербезопасность",
    procent: 67,
    bonus: 2.3,
    date: "На 7 дней"
  }
]

const calculateProfit = (total, procent, date) => {
   console.log(date);
   if(date === 'На 30 дней') {
    const res = total / 100 * procent * 30;
    return res.toFixed(1)

   } else if (date === 'На 14 дней')
   {
    const res = total / 100 * procent * 14;
    return res.toFixed(1)
   } else {
    const res = total / 100 * procent;
    return res.toFixed(1)
   }



}

const calclateTotalSum = (total, procent, date) => {
    const profit = calculateProfit(+total, procent, date);
    const totalSum = +profit + +total;
    return  totalSum.toFixed(1);
}

const setDepositDate = (time) => {
  let dayCount;
  if(time.includes('часа')) {
    dayCount = 1;
  } else {
    dayCount = time.match(/\d+/)[0];
  }
  let customDate = new Date(Date.now() + ( 3600 * 1000 * 24 * dayCount))
  return customDate.toLocaleString();
}

 const ClientPanel = () => {

  const { getCollapseProps, getToggleProps } = useCollapse();

  const [currentRadio, setCurrentRadio] = useState("seven");
  const [currentTerms, setCurrentTerms ] = useState(options[6]);
  const [currentSum, setCurrentSum] = useState();
  const [currentDeposit, setCurrentDeposit] = useState(0);

  const [activeIndex, setActiveIndex] = useState(3);

  
  

  const { t } = useTranslation();


  const onChangeRadioState = (e, number) => {
    e.preventDefault();
    setCurrentRadio(e.target.id);
    console.log(e);
    console.log(number);
    setCurrentTerms(options[number]);
  }

  useEffect(() => {
    const email = localStorage.getItem("login");
    const token = localStorage.getItem("token");
    getDepositSum(email, token).then(data => {
      setCurrentDeposit(data.deposit)
    }).catch(e => {

      if(e.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("login");
        window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`);
      };
    })
  }, [])


  const onCreateDeposit = () => {
 
   

    const create = () => {
      const email = localStorage.getItem('login');
      const token = localStorage.getItem('token');
      const depositEndDate = setDepositDate(currentTerms.date);
      const totalSum = calclateTotalSum(currentSum, currentTerms.procent, currentTerms.date);
      const profit = calculateProfit(currentSum, currentTerms.procent, currentTerms.date);
      const sumAfterDeposit = +currentDeposit - +currentSum;
      createDeposit(email, token, currentSum, currentTerms, depositEndDate, totalSum, profit, sumAfterDeposit).then(data => {
        console.log(data);
        window.location.reload();
      })
    }

console.log(currentSum);
    if(currentSum === undefined) {
      alert("Введите сумму депозита!");
      return;
      } 

    if(currentDeposit <  currentSum) {
   alert("Недостаточно средств на балансе");
   } 
   else if(currentDeposit < 100 && currentSum !== undefined) {
    alert("Минимальная сумма депозита - 100$");
   }
   else if(currentTerms.time  === "Индекс '20 акций'") {

    if(currentSum < 100 || currentSum > 1000) {
      alert("Вы  можете внести от 100 до 1000 $");
    } else {
      create();
    }

   }
   else if(currentTerms.time  === "Индекс '35 акций'") {
    if(currentSum < 1000 || currentSum > 2000) {
      alert("Вы  можете внести от 1000 до 2000 $");
    } else {
      create();
    }
   }
   else if(currentTerms.time  === "Индекс '50 акций'") {
    if(currentSum < 2000 || currentSum > 3000) {
      alert("Вы  можете внести от 2000 до 3000 $");
    } else {
      create();
    }
   }
   else if(currentTerms.time  === "Поздние стадии развития") {
    if(currentSum < 3000 || currentSum > 5000) {
      alert("Вы  можете внести от 3000 до 5000 $");
    } else {
      create();
    }
   }
   else if(currentTerms.time  === "Средняя стадия развития ") {
    if(currentSum < 5000 || currentSum > 10000) {
      alert("Вы  можете внести от 5000 до 10000 $");
    } else {
      create();
    }
   }
   else if(currentTerms.time  === "Начальная стадия развития") {
    if(currentSum < 10000 || currentSum > 50000) {
      alert("Вы  можете внести от 10000 до 50000 $");
    } else {
      create();
    }
   }
   else if(currentTerms.time  === "Финтех, зеленая энергетика") {
    if(currentSum < 15000 || currentSum > 25000) {
      alert("Вы  можете внести от 15000 до 25000 $");
    } else {
      create();
    }
   }
   else if(currentTerms.time  === "Электронная комерция") {
    if(currentSum < 25000 || currentSum > 55000) {
      alert("Вы  можете внести от 25000 до 55000 $");
    } else {
      create();
    }
   }
   else if(currentTerms.time  === "Кибербезопасность") {
    if(currentSum < 55000) {
      alert("Вы  можете внести от 55000$");
    } else {
      create();
    }
   }   
 
  }

console.log(currentTerms);

  return (
    <>
      <div className="clientPanel">
      <div class="row page-titles">
                    <div class="flex-boxx">   
					   <h3 class="text-themecolor">{t("c35")}</h3>
					   
                        <div class="breadcrumb">
                            <div class="breadcrumb-item">{t("c36")}</div>
                            <div className='next'>&gt;</div>
                            <div class="breadcrumb-item">{t("c37")}</div>
                            <div className='next'>&gt;</div>
                            <div class="breadcrumb-item">{t("c38")}</div>
                        </div>
                    </div>
                    
         </div>

         

       <div className='main-content-cleint-wrapper'>
                 <h3 className="text-themecolor-2">{t("c1")}</h3>

                 
        
        <div className='main-content-client'>
        <div className='left'>




    <div>




        <button className="btn-collapse"
                type="button"
                data-toggle="collapse"
                onClick={event => setActiveIndex(
                    activeIndex === 1 ? null : 1
                )}>
                Инвестиция в Хедж-фонд 
        </button>


        <Collapse isOpened={activeIndex === 1}>
            <div
                className={"alert alert-info msg"}
                >
              
<div className='somepoint'>

</div>

              <Formik
      initialValues={{
        picked: '',
      }}
      onSubmit={async (values) => {
        await new Promise((r) => setTimeout(r, 500));
        alert(JSON.stringify(values, null, 2));
        alert("check");
      }}
      // onChange={(e) => console.log(e) }
    >
      {({ values }) => (
        <Form>
          <div role="group" className='left-inside' aria-labelledby="my-radio-group">

            <div className={`wrapper-radio ${currentRadio === "first" ? "blue-box" : null}`}>
            <label>
              <Field id="first"  type="radio" name="picked" value="One" className="custom-checkbox" onChange={e => onChangeRadioState(e, 0)}/>
               <div className="checkbox-field" for="first">


               <div className='radio-icon-box'>

<div className='radio-title'>
<img className='logo-big-icon' src={t4} />
  <div>Индекс "20 акций"</div>
  </div>


<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span >Депозит на 30 дней</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>1.1% день</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>$100-1000</span>
</div>

</div>

               
               </div>
            </label>
            </div>
        
            <div className={`wrapper-radio ${currentRadio === "second" ? "blue-box" : null}`}>
            <label>
              <Field type="radio" id="second" name="" value="Two" className="custom-checkbox" onChange={e => onChangeRadioState(e, 1)}/>
              <div for="second" className="checkbox-field">

             

              <div className='radio-icon-box'>

<div className='radio-title'>
<img className='logo-big-icon' src={t2} />
  <div>Индекс "35 акций"</div>
  </div>


<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span >Депозит на 30 дней</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>1.4% день</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span> $1000-2000</span>
</div>

</div>


               </div>
            </label>
            </div>

            <div className={`wrapper-radio ${currentRadio === "third" ? "blue-box" : null}`}>
            <label>
              <Field type="radio" id="third" name="picked" value="Three" className="custom-checkbox" onChange={e => onChangeRadioState(e, 2)} />
              <div for="third" className="checkbox-field">


            
              <div className='radio-icon-box'>

<div className='radio-title'>
<img className='logo-big-icon' src={t3} />
  <div>Индекс "50 акций"</div>
  </div>


<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span >Депозит на 30 дней</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>2.1% день</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>$2000-3000</span>
</div>



</div></div></label></div></div>
</Form>
      )}
    </Formik>

             </div>








        </Collapse>




        <button className="btn-collapse"
                type="button"
                data-toggle="collapse"
                onClick={event => setActiveIndex(
                    activeIndex === 2 ? null : 2
                )}
  >
                Венчурные инвестиции 
        </button>







      
        <Collapse isOpened={activeIndex === 2}>
            <div
                className={`alert alert-info msg ${activeIndex === 1 ? 'show' : null} ${activeIndex !== 2 ? 'hide' : null}`}>
              


        <Collapse isOpened={activeIndex === 2}>
            <div
                className={"alert alert-info msg"}
                >
              
<div className='somepoint'>

</div>

              <Formik
      initialValues={{
        picked: '',
      }}
      onSubmit={async (values) => {
        await new Promise((r) => setTimeout(r, 500));
        alert(JSON.stringify(values, null, 2));
        alert("check");
      }}
      // onChange={(e) => console.log(e) }
    >
      {({ values }) => (
        <Form>
          <div role="group" className='left-inside' aria-labelledby="my-radio-group">

            <div className={`wrapper-radio ${currentRadio === "four" ? "blue-box" : null}`}>
            <label>
              <Field id="four"  type="radio" name="picked" value="Four" className="custom-checkbox" onChange={e => onChangeRadioState(e, 3)}/>
               <div className="checkbox-field" for="four">


               <div className='radio-icon-box'>

<div className='radio-title'>
<img className='logo-big-icon' src={t4} />
  <div>Поздняя стадия развития</div>
  </div>


<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span >Депозит на 14 дней</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>2.1% день</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>$3000-5000</span>
</div>

</div>

               
               </div>
            </label>
            </div>
        
            <div className={`wrapper-radio ${currentRadio === "five" ? "blue-box" : null}`}>
            <label>
              <Field type="radio" id="five" name="" value="Five" className="custom-checkbox" onChange={e => onChangeRadioState(e, 4)}/>
              <div for="five" className="checkbox-field">
              <div className='radio-icon-box'>

<div className='radio-title'>
<img className='logo-big-icon' src={t2} />
  <div>Средняя стадия развития</div>
  </div>


<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span >Депозит на 14 дней</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>2.6% день</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>$5000-10000</span>
</div>

</div>


               </div>
            </label>
            </div>

            <div className={`wrapper-radio ${currentRadio === "six" ? "blue-box" : null}`}>
            <label>
              <Field type="radio" id="six" name="picked" value="Six" className="custom-checkbox" onChange={e => onChangeRadioState(e, 5)} />
              <div for="six" className="checkbox-field">


            
              <div className='radio-icon-box'>

<div className='radio-title'>
<img className='logo-big-icon' src={t3} />
  <div>Начальная стадия развития</div>
  </div>


<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span >Депозит на 14 дней</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>3.2% день</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>$10000-50000</span>
</div>



</div>



               </div>
            </label>
            </div>




          </div>

    
        </Form>
      )}
    </Formik>

             </div>
        </Collapse>


             </div>
        </Collapse>







        <button className="btn-collapse"
                type="button"
                data-toggle="collapse"
                onClick={event => setActiveIndex(
                    activeIndex === 3 ? null : 3
                )}>
              Перспективные стартапы
        </button>
        <Collapse isOpened={activeIndex === 3}>
            <div
                className={"alert alert-info msg"}
                >
      


      <div className='somepoint'>

</div>
<div className='somepoint'>

</div>
              <Formik
      initialValues={{
        picked: '',
      }}
      onSubmit={async (values) => {
        await new Promise((r) => setTimeout(r, 500));
        alert(JSON.stringify(values, null, 2));
        alert("check");
      }}
      // onChange={(e) => console.log(e) }
    >
      {({ values }) => (
        <Form>
          <div role="group" className='left-inside' aria-labelledby="my-radio-group">

            <div className={`wrapper-radio ${currentRadio === "seven" ? "blue-box" : null}`}>
            <label>
              <Field id="seven"  type="radio" name="picked" value="Seven" className="custom-checkbox" onChange={e => onChangeRadioState(e, 6)}/>
               <div className="checkbox-field" for="seven">


               <div className='radio-icon-box'>

<div className='radio-title'>
<img className='logo-big-icon' src={t4} />
  <div>Финтех, зеленая энергетика</div>
  </div>


<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span >Депозит на 7 дней</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>42% за период депозита</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>$15000+</span>
</div>

</div>

               
               </div>
            </label>
            </div>
        
            <div className={`wrapper-radio ${currentRadio === "eight" ? "blue-box" : null}`}>
            <label>
              <Field type="radio" id="eight" name="" value="Eight" className="custom-checkbox" onChange={e => onChangeRadioState(e, 7)}/>
              <div for="eight" className="checkbox-field">
              <div className='radio-icon-box'>

<div className='radio-title'>
<img className='logo-big-icon' src={t2} />
  <div>Электронная коммерция, <br /> Новые материалы</div>
  </div>


<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span >Депозит на 7 дней</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>54% за период депозита</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>$25000+</span>
</div>

</div>


               </div>
            </label>
            </div>

            <div className={`wrapper-radio ${currentRadio === "nine" ? "blue-box" : null}`}>
            <label>
              <Field type="radio" id="nine" name="picked" value="Nine" className="custom-checkbox" onChange={e => onChangeRadioState(e, 8)} />
              <div for="nine" className="checkbox-field">


            
              <div className='radio-icon-box'>

<div className='radio-title'>
<img className='logo-big-icon' src={t3} />
  <div>Кибер безопасность, <br /> Искуственный интеллект</div>
  </div>


<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>Депозит на 7 дней</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>67% за период депозита</span>
</div>

<div className='radio-list'>
<img className='logo-sm-icon' src={ok} />
<span>$55000+</span>
</div>



</div>



               </div>
            </label>
            </div>




          </div>

    
        </Form>
      )}
    </Formik>







             </div>
        </Collapse>


    </div>

    <div className='somepoint'>

</div>
     

        </div>
        <div className='right'>

      <div className='right-title'>
          <div>{`${t(currentTerms.time)} `}</div>
          <div>{`${t("c18")} ${currentTerms.procent}% ${  currentTerms.procent === 42 || currentTerms.procent === 54 || currentTerms.procent === 67 ? "за период депозита" :"в день"}`}</div>
      </div>

      <div className='right-panel'>
       <div className='right-panel-title'>{t("c2")}</div>
       <div className='right-panel-amount'>{currentDeposit.toFixed(2)} $</div>
       <div className='right-panel-title'>{t("c3")}</div>
       <div className='right-input-field'>
        <input  type="number" placeholer="Введите сумму"  onChange={e => setCurrentSum(e.target.value)}/>
        <div>	&#36;</div>
       </div>
       <div className='right-panel-title small'>{t("c4")}</div>
       <button type="submit" onClick={onCreateDeposit} className='btn-dep'>{t("c33")}</button>


      </div>
      <div className="right-panel-info">{t("c5")}</div>
      
      
      <div className='right-panel-flex'>


        <div className='right-panel-flex-left'>

          <div className='right-panel-flex-left-inside'>
          <div>
          <img className='logo-big-icon' src={ok} />
          </div>

          <div className='right-panel-flex-left-inside-div'>
            <span>{t("c6")}</span>
            <div className='text-green font-max'>{`${currentSum ? calculateProfit(currentSum, currentTerms.procent, currentTerms.date) : '0'} $`}</div>
          </div>
          </div>

          <div className='right-panel-flex-left-inside'>
            
          <div>
          <img className='logo-big-icon' src={ok} />
          </div>

          <div className='right-panel-flex-left-inside-div'>
            <span>{t("c7")}</span>
            <div className='font-max'>{`${currentSum ? calclateTotalSum(currentSum, currentTerms.procent, currentTerms.date) : '0'} $`}</div>
          </div>

          </div>

          <div className='right-panel-flex-left-inside'>
          <div>
          <img className='logo-big-icon' src={ok} />
          </div>
          <div className='right-panel-flex-left-inside-div'>
            <span>{t("c8")}</span>
            <div>{setDepositDate(currentTerms.date)}</div>
          </div>
          </div>
          
          </div>


        <div className='right-panel-flex-right'>

          <div className='right-panel-flex-right-inside'>
            <div>{t("c9")}</div>
            <span>{`${currentTerms.procent}% ${  currentTerms.procent === 42 || currentTerms.procent === 54 || currentTerms.procent === 67 ? "за период депозита" :"в день"
            }.`}</span>
          </div>
 
      
          {/* <div className='right-panel-flex-right-inside'>
            <div>{t("c11")}</div>
            <span>{t("c34")}</span>
          </div> */}

          <div className='right-panel-flex-right-inside'>
            <div>{t("c12")}</div>
            <span>{t("c22")}</span>
          </div>

        </div>
      </div>


      <div className='text-block'>
      {t("c21")}
      </div>

        </div>

          </div>
                </div>

      </div>
    </>
  );
};

export default ClientPanel;
