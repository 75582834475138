import React, { useEffect, useState } from 'react';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import { loginUser } from '../../api/user';


import ParticlesComponent from "../../components/Particles";
import {formLoginValues}  from "../../utils/formData"
import {validationLoginSchema} from "../../utils/validationSchema"
import NotFound from '../NotFound/NotFound';
import eye from '../../image/eye.png';
import backpic from '../../image/back_new.jpg';
import ChangePassword from '../../components/ChangePassword/ChangePassword';



import "./Styles.css"





 const Login = () => {




  const [error, setError] = useState('');
  const [isSetToken, setToken] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(true)
  const [isModal, setIsModal] = useState(false);


  useEffect(() => {
    const isToken = localStorage.getItem("token");
    if(isToken) {

        window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/deposit`);

    } else {
      setToken(true);
    }
 
  }, [])



  const openModalChangePassword = () => {
    alert("Ведутся технические работы.")
    // setIsModal(true);
  }


    const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
     console.log(values);

     try {
          loginUser(values).then((userData) => {
            console.log(userData);
            if (userData.token) {
              const { token } = userData;
              const currentToken = token.replace(/Bearer /i, '');
              localStorage.setItem('token', currentToken);
              localStorage.setItem('login', values.email);
              debugger;
              // dispatch(setIsLoginTrue());
              window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/client/deposit`)
            } else {
              setError(Object.values(userData).toString());
            }
          });
     } catch(e) {
      console.log(e);
     }
     }

  return (
    <>

{ isSetToken ? 
  
  <>
  
  
  {/* <ParticlesComponent /> */}

  <div className='bckgrnd_pic'>
       <img className="bckgrnd_picture" src={backpic} alt=""  />
       </div>


   { isModal 
   ?   (<ChangePassword />)
   :
   <div className='form-div'>
   <div className='form-container'>
     <h1>Вход</h1>
     <p class="signup-link register">Войдите, чтобы продолжить</p>
 <Formik
   initialValues={{
     email: '',
     password: '',
   }}
   validationSchema={validationLoginSchema}
   onSubmit={handleFormSubmit}
 >
   
   {(errors, touched, ) => {
     return (

       <Form>

           {formLoginValues.map(({ id, name, type, placeholder, label, d, cx, cy, r }) => (
             <div key={id} className='field-container'>

               <label htmlFor={name} className="form-label">{label}</label>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                 <path d={d}></path>

                 {placeholder === "*****" ? (
                   <rect x={cx} y={cy} width="18" height="11" rx="2" ry="2"></rect>
                 ) : (
                       <circle cx={cx} cy={cy} r={r}></circle>
                 )}
             
                 </svg>


                 {placeholder === "*****" ? (
                          <Field
                          type={!hiddenPassword ? "text" : "password"}
                          id={name}
                          name={name}
                          placeholder={placeholder}
                          className="custom-field"
            
                        />
                      ) 
                      : 
                      (   
                      <Field
                       id={name}
                       name={name}
                       placeholder={placeholder}
                       className="custom-field"
         
                     />)
                      }

                 <div className='error-wrapper'>
                 <ErrorMessage name={name} />
                 </div>
                 {error && name === "password" ? <div className='error-wrapper'>{error}</div> : null}
                 {placeholder === "*****" ?  
                    <div onClick={() => setHiddenPassword((prevState) => !prevState)} className='hiddenPswrd'>
                      <img className='logo-eye' alt="pic" src={eye} />
                    </div> 
                    : null}  {placeholder === "*****" ?  
                    <div onClick={() => setHiddenPassword((prevState) => !prevState)} className='hiddenPswrd'>
                      <img className='logo-eye' alt="pic" src={eye} />
                    </div> 
                    : null}
             </div>
           ))}
       
         
           <button type="submit" className='btn'>ВОЙТИ</button>

       </Form>
     );
   }}
 </Formik>
 <p class="signup-link">У Вас все еще нет аккаунта?  <a href="/create-account">Откройте его прямо сейчас</a></p>
 <p class="signup-link2" onClick={() => openModalChangePassword()}>Забыли пароль?</p>
 </div>
 </div>
    }
  

  </>
  :
  <NotFound />
 }
    </>
  );
};

export default Login;
