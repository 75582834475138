
import React, { useEffect, useState } from 'react';

import WithdrawRequestTable from '../../components/WithdrawRequestTable/WithdrawRequestTable';

import { getAllWithdrawRequest } from '../../api/user';
import "./Styles.css"
import { t } from 'i18next';


const WithdrawHistory = () => { 

    const [withdrawRequestList, setWithdrawRequestList] = useState([]);



    useEffect(() => {
        const email = localStorage.getItem("login");
        const token = localStorage.getItem("token");
        getAllWithdrawRequest( token, email).then(data => {
        console.log(data);
        setWithdrawRequestList(data.withdrawArray)
       }).catch(e => {
        if(e.response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("login");
            window.location.replace(`${process.env.REACT_APP_FRONTEND_URI}/login`);
          };
       })
    }, [])


    return (
        <div className="clientPanel">

        <div class="row page-titles">
                            <div class="flex-boxx">   
                               <h3 class="text-themecolor">{t("r58")}</h3>
                               
                                <div class="breadcrumb">
                                    <div class="breadcrumb-item">{t("c36")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">{t("c37")}</div>
                                    <div className='next'>&gt;</div>
                                    <div class="breadcrumb-item">{t("r59")}</div>
                                </div>
                            </div>          
        </div>

        <div className='deposit_bottom_panel'>
    <h2>{t("r55")}</h2>
    <div className='deposit_bottom_panel_table'>
        {withdrawRequestList.length === 0 ? 
        <span>{t("r56")}</span> :
        <WithdrawRequestTable data={withdrawRequestList}/>
    }
        
    </div>

</div>


        </div>
    )
}


    export default WithdrawHistory;